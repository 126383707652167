import request from '@/utils/api_req.js'

/**
 * TG群随机拉起
 *
 */
export function getRandomOne(typeId) {
  return request.get('tg/group/random/one/' + typeId)
}

/**
 * 拉起费用支付
 *
 */
export function pullPay(data) {
  return request.post('tg/group/pull/pay', data)
}

/**
 * 我拉取的群历史
 *
 */
export function pullHis(data) {
  return request.get('tg/group/pull/his', data)
}

/**
 * 首页轮播图
 *
 */
export function info(data) {
  return request.get('tg/group/info', data, {
    noAuth: true
  })
}

/**
 * 首页轮播图
 *
 */
export function infoById(id) {
  return request.get('tg/group/info/' + id, {}, {
    noAuth: true
  })
}

/**
 * 随机客户
 *
 */
export function queryCusList(data) {
  return request.get('tg/group/cusList', data, {
    noAuth: true
  })
}

/**
 * 常见问题
 *
 */
export function queryNormalIssue(data) {
  return request.get('tg/group/normalIssue', data, {}, data)
}

/**
 * 钱包地址是否有效
 *
 */
export function addressValid(address) {
  return request.get('tg/group/walletCheck/' + address)
}

/**
 * 检查支付密码
 *
 */
export function checkpwd(pwd) {
  return request.get('tg/group/checkpwd/' + pwd)
}

/**
 * 我的推广-收益详情-合伙人
 *
 */
export function partnerInfo(data) {
  return request.get('retail/store/spread/income/partner/info', data)
}

/**
 * 我的推广-收益详情-合伙人
 *
 */
export function partnerList(data) {
  return request.get('retail/store/spread/invite/partner/list', data)
}

/**
 * 我的推广-我的推广数据
 */
export function spreadData() {
  return request.get('retail/store/spread/my/data')
}

/**
 * 我的推广-推广详情
 */
export function incomeData(data) {
  return request.get('retail/store/spread/income/user/info', data)
}

/**
 * 我的推广-推广详情
 */
export function inviteData(data) {
  return request.get('retail/store/spread/invite/info/count', data)
}

/**
 * 我的推广-推广详情
 */
export function inviteDataList(data) {
  return request.get('retail/store/spread/invite/info/list', data)
}

/**
 * 我的推广-推广详情
 */
export function rankDataList(data) {
  return request.get('retail/store/spread/brokerage/rank', data)
}

/**
 * 我的推广-推广详情
 */
export function myRankData(data) {
  return request.get('retail/store/spread/brokerage/my/rank', data)
}

/**
 * 是否申请
 */
export function isApplyPartner(data) {
  return request.get('partner/isApply', data)
}

/**
 * 保存
 */
export function addPartner(data) {
  return request.post('partner/add', data)
}

/**
 * 信息
 */
export function partnerAuditInfo(data) {
  return request.get('partner/info', data)
}

/**
 * 信息
 */
export function spreadInfo(data) {
  return request.get('partner/spread/info', data)
}
