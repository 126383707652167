<template>
  <div class="width_1200 container-top">
    <div class="position-relative">
      <a-image :src="require('@/assets/danbao/top.png')" :preview="false" :height="180" :width="'100%'"></a-image>
      <div class="img-btn">
        <a-button class="record-btn mr-3" @click="records">
          我的担保记录
          <template #icon>
            <Icon type="md-list-box" :size="22"/>
          </template>
        </a-button>
        <!--        <Icon class="pointer" type="md-alert" color="#2BA0FF" size="32" @click="getIssueList"/>-->
      </div>
    </div>
    <div class="mt-3 width_1200">
      <!--      <a-card :bodyStyle="{padding: '10px 20px 10px 20px'}">-->
      <!--        <div class="flex justify-space-between align-xl-center">-->
      <!--          <div class="mr-10 font-danbao">-->
      <!--            <a-breadcrumb separator=">">-->
      <!--              <a-breadcrumb-item>-->
      <!--                <router-link to="/">-->
      <!--                  首页-->
      <!--                </router-link>-->
      <!--              </a-breadcrumb-item>-->
      <!--              <a-breadcrumb-item>-->
      <!--                <router-link to="#" @click="step = 1">-->
      <!--                  <span class="cursor-pointer">担保交易</span>-->
      <!--                </router-link>-->
      <!--              </a-breadcrumb-item>-->
      <!--              <a-breadcrumb-item v-if="step === 2">-->
      <!--                发起担保-->
      <!--              </a-breadcrumb-item>-->
      <!--              <a-breadcrumb-item v-if="step === 3">-->
      <!--                担保确认支付-->
      <!--              </a-breadcrumb-item>-->
      <!--              <a-breadcrumb-item v-if="step === 4">-->
      <!--                我的担保记录-->
      <!--              </a-breadcrumb-item>-->
      <!--            </a-breadcrumb>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </a-card>-->
      <!--      列表-->
      <a-card :bodyStyle="{padding: '0'}">
        <a-image :preview="false" :src="require('@/assets/danbao/top.jpg')" :width="1200" :height="160"/>
      </a-card>
      <div v-if="step === 1">
        <empty v-if="cusList.length === 0"/>
        <div v-else>
          <a-row :gutter="16">
            <a-col :span="6" class="list-g-x" v-for="(item, index) in cusList" :key="index">
              <a-card :loading="loading" :bordered="false" :bodyStyle="{padding: '20px',height:'381px'}"
                      @click="viewCard(item)" class="cursor-pointer">
                <div>
                  <a-avatar :size="230" :src="item.headImg"/>
                </div>
                <div class="list-title cursor-pointer line1">{{ item.userName }}</div>
                <!--                <div class="list-content cursor-pointer line1">擅长: {{ item.typeName }}</div>-->
                <div class="mt-3 btm-btns">
                  <div class="mr-4 danbao-cell-btn1 cursor-pointer" @click="openLink(item.tgUrl)">
                    <a-image :preview="false" :src="require('@/assets/danbao/link.png')" :width="24"
                             :height="24"></a-image>
                    <span class="ml-1">交谈</span>
                  </div>
                  <div class="danbao-cell-btn2 cursor-pointer" @click="sendTg(item.typeId)">
                    发起担保
                  </div>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
        <!-- 分页 -->
        <div class="page-size" v-if="cusList && cusList.length > 0">
          <a-pagination :page-size="limit" :total="total" :show-size-changer="false" show-less-items
                        @change="changePageNum" @page-size-change="changePageSize"/>
        </div>

      </div>

      <!--      担保 -->
      <div class="process-step" v-if="step > 1 && step <= 3">
        <a-card :bodyStyle="{padding: '20px'}">
          <a-steps :current="current" label-placement="vertical">
            <a-step title="发起担保" :disabled="true">
              <template #icon>
                <Avatar shape="circle" :style="current >= 0 ? {background: color} : {}"
                        icon="md-person" size="large"/>
              </template>
            </a-step>
            <a-step title="支付中" :disabled="true">
              <template #icon>
                <Avatar shape="circle" :style="current >= 1 ? {background: color} : {}" icon="md-card" size="large"/>
              </template>
            </a-step>
            <a-step title="担保中" :disabled="true">
              <template #icon>
                <Avatar shape="circle" :style="current >= 2 ? {background: color} : {}" icon="md-checkmark"
                        size="large"/>
              </template>
            </a-step>
          </a-steps>
        </a-card>
      </div>

      <div v-if="step === 2">
        <div class="process-step">
          <a-card title="担保明细" :bodyStyle="{padding: '20px'}">
            <div class="flex">
              <div class="content-title">发起人:</div>
              <div class="content-info">{{ tgData.tgManager.userName }}</div>
            </div>
            <div class="flex">
              <div class="content-title">发起时间:</div>
              <div class="content-info">{{ tgData.createTime }}</div>
            </div>
            <div class="flex">
              <div class="content-title">拉起打包群费:</div>
              <div class="content-info font-1">{{ tgData.tgAmount + ' ' }}USDT</div>
            </div>
          </a-card>
        </div>

        <div class="process-step">
          <div class="pay-btn cursor-pointer" @click="payMent">去支付</div>
        </div>

        <div class="process-step">
          <div class="warning-font">
            <Icon type="ios-alert" color="#FDBD28" size="22"/>
            温馨提示:一旦拉起担保群成功费用无法退回！
          </div>
        </div>
      </div>
      <!--      成功 -->
      <div v-if="step === 3">
        <div class="process-step">
          <a-card>
            <div class="cu-center">
              <a-image width="138px" height="138px" :src="require('@/assets/danbao/suc.png')"></a-image>
            </div>
            <div class="flex cu-center mt-8">
              <div class="mr-10 join-btn cu-center cursor-pointer" @click="toOpen">加入担保群</div>
              <div class="share-btn cu-center cursor-pointer" @click="share">
                <Icon type="md-copy"/>
                分享担保群
              </div>
            </div>
            <div class="flex cu-center mt-5">
              <div class="cu-center">如果遇到问题，点击此处<span class="curl-link">
                <router-link to="" @click="openChatIm">
                联系客服
               </router-link>
              </span>
              </div>
            </div>
          </a-card>
        </div>
      </div>

      <!--      历史 -->
      <div v-if="step === 4">
        <div class="process-step">
          <empty v-if="hisList.length === 0"/>
          <a-card :bodyStyle="{padding: '20px'}" v-else>
            <a-list class="demo-loadmore-list"
                    :loading="listLoading"
                    item-layout="horizontal"
                    :data-source="hisList">
              <template #renderItem="{ item }">
                <a-list-item>
                  <template #actions>
                    <div class="round bg-red cursor-pointer" style="width: 96px !important;"
                         v-if="item.orderStatus === 0">未支付
                    </div>
                    <div>
                      <div class="rounded-xl mb-3 bg-blue cursor-pointer" style="width: 96px !important;"
                           v-if="item.orderStatus === 1 || item.orderStatus === 2"
                           @click="openLink(item.tgUrl)">
                        打开链接
                      </div>
                      <div class="rounded-xl bg-green cursor-pointer" style="width: 96px !important;"
                           v-if="item.orderStatus === 1">
                        担保中
                      </div>
                      <div class="rounded-xl bg-gray cursor-pointer" style="width: 96px !important;"
                           v-if="item.orderStatus === 2">完成
                      </div>
                    </div>
                  </template>
                  <a-skeleton avatar :title="false" :loading="!!listLoading" active>
                    <a-list-item-meta :description="'发起时间:' + item.createTime">
                      <template #title>
                        订单号: {{ item.hisOrder }} <span class="text-grey ml-10">发起金额: {{
                          item.tgAmount
                        }} USDT</span>
                      </template>
                    </a-list-item-meta>
                  </a-skeleton>
                </a-list-item>
              </template>
            </a-list>
          </a-card>
        </div>
        <!-- 分页 -->
        <div class="page-size mt-5" v-if="hisList && hisList.length > 0">
          <a-pagination :page-size="listLimit" :total="listTotal" show-less-items @change="changeListPageNum"
                        :show-size-changer="false"
                        @page-size-change="changeListPageSize"/>
        </div>
      </div>

      <a-card class="mt-3 mb-5" :bodyStyle="{padding: '0'}">
        <a-image :preview="false" :src="require('@/assets/danbao/bottom.jpg')" :width="1200" :height="160"/>
      </a-card>

      <!--      常见问题 -->
      <div class="process-step mb-6">
        <a-card class="margin-top-sm" :bordered="false" :body-style="{padding: '10px'}">
          <template #title>
            <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="32" :height="32"></a-image>
            常见问题
          </template>
          <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
            <a-collapse-panel :header="item.issue" v-for="(item, index) in helpList" :key="index">
              <div class="html-font" v-html="item.answer"></div>
            </a-collapse-panel>
          </a-collapse>
        </a-card>
      </div>
    </div>

    <Payment :visible="payVisible" @complete="onComplete"/>
    <ShopTip :show-model="showModel" :tips="tipsOne"></ShopTip>
  </div>
</template>

<script>
import { getRandomOne, pullHis, pullPay, queryCusList, queryNormalIssue } from '@/api/tg.js'
import { isSet } from '@/api/user.js'

import Payment from '@/components/payment/index.vue'
import ShopTip from '@/components/shopTip/index.vue'

export default {
  name: 'Guarantee',
  components: {
    Payment,
    ShopTip
  },
  data() {
    return {
      activeKey: '',
      issueList: [],
      page: 1,
      limit: 12,
      total: 0,
      cusList: [],
      loading: false,
      step: 1,
      current: 0,
      tgData: {},
      color: '#2BA0FF',
      payVisible: false,
      payPassword: '',
      showModel: false,
      tipsOne: '您的余额不足,去充值?',
      tgShareUrl: '',
      listPage: 1,
      listLimit: 12,
      listTotal: 0,
      hisList: [],
      helpList: [],
      listLoading: false,
    }
  },
  mounted() {
  },
  created() {
    const id = this.$route.query.id
    if (id) {
      this.sendTg(id)
    } else {
      this.getDbCusList()
    }
    this.getIssueList()
  },
  methods: {
    openChatIm() {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: 0
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    getIssueList() {
      let data = {
        page: 1,
        limit: 100,
        issueType: 0
      }
      queryNormalIssue(data)
          .then(res => {
            this.helpList = res.data.list
          })
    },
    records() {
      // this.step = 4
      this.$router.push({
        path: '/GuaranteeRecord',
        query: {}
      })
    },
    changeListPageNum(val) { // 修改页码
      this.listPage = val
      this.getPullHisList()
    },
    changeListPageSize(val) { // 修改页数
      this.listPage = 1
      this.listLimit = val
      this.getPullHisList()
    },
    getPullHisList() {
      let that = this
      that.listLoading = true
      let data = {
        page: that.listPage,
        limit: that.listLimit
      }
      pullHis(data)
          .then(function (res) {
            that.hisList = res.data.list
            that.listTotal = res.data.total
            that.listLoading = false
          })
    },
    viewCard(item) {
    },
    toOpen() {
      window.open(this.tgShareUrl)
    },
    share() {
      let shareText = this.tgShareUrl
      this.$Copy({ text: shareText })
    },
    payMent() {
      isSet()
          .then((res) => {
            if (res.data) {
              this.tipsOne = '支付密码未设置,是否去设置?'
              this.showModel = true
            } else {
              this.current = 1
              this.payVisible = true
            }
          })
    },
    onComplete(e) {
      this.payVisible = false
      this.payClick(e)
    },
    payClick(e) {
      this.payPassword = e
      let data = {
        id: this.tgData.id,
        orderNo: this.tgData.hisOrder,
        payPassword: this.payPassword
      }
      pullPay(data)
          .then((res) => {
            if (res.code === 200) {
              if (res.data) {
                this.showModel = false
                this.tgShareUrl = res.data
                this.current = 2
                this.step = 3
              } else {
                this.tipsOne = '您的余额不足,去充值?'
                this.showModel = true
              }
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    sendTg(typeId) {
      this.getRandomOneQuery(typeId)
    },
    getRandomOneQuery(typeId) {
      getRandomOne(typeId)
          .then((res) => {
            if (res.code === 200) {
              this.tgData = res.data
              this.step = 2
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    openLink(url) {
      if (this.$store.getters.token) {
        const newVar = !url.startsWith('https://') ? 'https://t.me/' + url : url
        window.open(newVar)
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.getDbCusList()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.getDbCusList()
    },
    getDbCusList() {
      let data = {
        page: this.page,
        limit: this.limit
      }
      this.loading = true
      queryCusList(data)
          .then((res) => {
            this.cusList = res.data.list
            this.total = res.data.total
            this.loading = false
          })
    },
  }
}

</script>
<style scoped lang="scss">
.img-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;

}

.join-btn {
  width: 153px;
  height: 42px;
  border-radius: 6px;
  background: #269FFA;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;

  color: #FFFFFF;
}

.cu-tag {
  width: 63px;
  border-radius: 6px;
}

.share-btn {
  width: 153px;
  height: 42px;
  opacity: 1;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #269FFA;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;
  color: #269FFA;
}

.curl-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;
  color: #269FFA;
}


.warning-font {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #7E7E7E;
}

.pay-btn {
  top: 807px;
  width: 299px;
  height: 48px;
  border-radius: 8px;
  background: #269FFA;

  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;

}

.font-1 {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #E43737;
}

.content-title {
  width: 98px;
  height: 25px;

  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: #999999;
}

.content-info {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
}

.process-step {
  margin-top: 20px;
}

:deep(.ant-steps-item-title) {
  font-size: 14px;
  font-weight: 350;
  line-height: 25px;
  letter-spacing: 0px;
}

.ivu-avatar-large.ivu-avatar-icon {
  font-size: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.ant-steps-item-tail) {
  top: 12px !important;
  margin-inline-start: 60px !important;
}

.danbao-cell-btn1 {
  width: 121px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  color: #3D3D3D;
}

.danbao-cell-btn2 {
  width: 121px;
  height: 40px;
  border-radius: 5px;
  background: #269FFA;
  color: #FFFFFF;
}

.btm-btns {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.cursor-pointer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.list-title {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.list-content {
  margin-top: 6px;
  font-size: 14px;
  color: #999999;
}

.list-g-x {
  padding: 20px 0 20px 10px;
}

.record-btn {
  width: 147px;
  height: 36px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #298FFF;

  font-size: 14px;
  font-weight: 350;
  line-height: 26px;
  color: #269FFA;
}

.font-danbao {
  font-size: 16px;
  font-weight: 350;
  line-height: 26px;
  color: #3D3D3D;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1200px;
}
</style>
