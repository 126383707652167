<template>
  <div class="container width_1200_auto">
    <div class="mb-2 ml-1 d-flex">
      <span class="text-gray mr-1">您的当前位置: </span>
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <span class="cursor-pointer" @click="toIndex">首页</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <span class="cursor-pointer" @click="tolist">供需</span>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          <span class="cursor-pointer">{{ noteDetails.title }}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>

    <div>
      <a-card :bodyStyle="{padding: '10px'}">
        <div class="flex justify-sm-start flex-row card-height">
          <div style="border-right:1px solid #E4E4E4;">
            <div v-if="noteDetails.video">
              <vue3-video-play v-bind="playerOptions" :poster="noteDetails.video"/>
            </div>
            <div v-else>
              <a-carousel
                  autoplay
                  indicator-type="dot"
                  show-arrow="hover"
                  :style="{
                  width: '544px',
                  height: '592px',
                }">
                <a-carousel-item v-for="image in list3">
                  <a-image :preview="true" :width="544" :height="592" :src="image"
                           class="img-fit"
                           :fallback="require('@/assets/index/logo.png')">
                  </a-image>
                </a-carousel-item>
              </a-carousel>
            </div>
          </div>
          <div class="pa-10 infos">
            <div class="info-title line1">
              {{ noteDetails.title }}
            </div>
            <div class="info-time">
              发布时间:{{ noteDetails.createTime }}
            </div>
            <a-divider style="width: 100%;"></a-divider>
            <arco-scrollbar class="info-content"
                            style="white-space: pre-wrap;height: 380px;overflow-y: scroll;overflow-x:hidden !important;">
              {{ noteDetails.content }}
            </arco-scrollbar>
            <div class="info-product">
              <div class="info-link">
                联系方式: <span class="link">{{ noteDetails.telegram }}</span>
                <icon-copy @click="$Copy({text: noteDetails.telegram})"/>
              </div>
            </div>
          </div>
        </div>
      </a-card>
    </div>
    <div class="info-bottom" v-if="noteDetails.productList && noteDetails.productList.length > 0">
      <a-card>
        <div class="product">
          <arco-scrollbar type="embed" style="height:120px;overflow: auto;">
            <div class="flex">
              <div v-for="(items, index) in noteDetails.productList" :key="index" class="cursor-pointer"
                   @click="() => {$router.push({path:'/Product', query:{ id: items.productId}})}">
                <div class="imgs">
                  <a-image :preview="false" :src="items.productImage" :width="109" :height="106"
                           style="border-radius: 10px;"
                           :fallback="require('@/assets/index/logo.png')"/>
                  <div class="usdt-bottom">
                    {{ items.price }}USDT
                  </div>
                </div>
              </div>
            </div>
          </arco-scrollbar>
        </div>
      </a-card>
    </div>

    <div class="info-bottom">
      <a-card>
        <div>
          <div class="info-content-title mb-3">提醒声明</div>
          <div class="info-content">1、51担保平台对发布的信息不确保真实、安全性。不建议私下交易，可通过平台担保交易更安全；
          </div>
          <div class="info-content">2、非经过平台线上的交易，请勿联系平台客服处理，如对方要求私下交易的请联系客服或举报；
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import { noteDetailApi } from '@/api/discover.js'

export default {
  name: 'info',
  data() {
    return {
      fromData: { id: '' },
      list3: [],
      noteDetails: {},
      playerOptions: {
        width: '544px', //播放器高度
        height: '592px', //播放器高度
        color: '#409eff', //主题色
        muted: false, //静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
        autoPlay: false, //自动播放
        loop: false, //循环播放
        mirror: false, //镜像画面
        ligthOff: false,  //关灯模式
        volume: 0.3, //默认音量大小
        control: true, //是否显示控制器
        title: '', //视频名称
        src: '', //视频源
        poster: '', //封面
      }
    }
  },
  mounted() {
    this.getNoteDetail()
  },
  created() {
    this.fromData.id = this.$route.query.id
  },
  methods: {
    load({ done }) {
      done('empty')
    },
    // 内容详情
    getNoteDetail() {
      noteDetailApi(this.fromData.id)
          .then(res => {
            this.noteDetails = res.data
            this.list3 = []
            if (this.noteDetails.image) {
              this.list3 = this.noteDetails.image.split(',')
            }
            // if (this.noteDetails.cover) {
            //   this.list3.push(this.noteDetails.cover)
            // }
            if (this.noteDetails.video) {
              this.playerOptions.poster = this.noteDetails.cover
              this.playerOptions.src = this.noteDetails.video
            }
          })
          .catch(err => {
          })
    },
    toIndex() {
      this.$router.push({
        path: '/',
        query: {}
      })
    },
    tolist() {
      this.$router.push({
        name: 'Supply',
        query: {}
      })
    },
  }
}
</script>


<style scoped lang="scss">
.info-bottom {
  margin-top: 10px;

  .info-content-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #FF6F4A;
  }
}

.product {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-y: hidden !important;
}

.imgs {
  margin-right: 15px;
  position: relative;

  .usdt-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 24px;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;

    font-size: 12px;
    font-weight: 900;
    line-height: 26px;
    color: #FFFFFF;

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.infos {
  flex: 1;
  position: relative;
}

.info-product {
  position: absolute;
  bottom: 10px;
  width: 86%;
}

.info-link {
  width: 86%;
  height: 52px;
  border-radius: 8px;
  background: #FFF9E7;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;

  font-size: 14px;
  line-height: 20px;
  color: #666666;

  .link {
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    color: #FF6F4A;
    margin-right: 10px;
    margin-left: 10px;
  }

  .copy {
    background: #999999;
  }
}

.info-content {
  font-size: 14px;
  line-height: 24px;
  color: #999999;
}

.info-title {
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
}

.info-time {
  font-size: 14px;
  font-weight: 350;
  color: #A7A7A7;
}

.container {
  margin: 0 auto;
  padding: 10px 0;
  border-radius: 12px;
  min-height: 1200px;
}
</style>
