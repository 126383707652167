<template>
  <div>
    <footer class="footer">
      <div class="clearfix"></div>
      <div class="service-intro">
        <div class="servece-type">
          <div class="servece-type-info" v-for="(guide, index) in guideArr" :key="index">
            <ul>
              <li v-for="(item, index1) in guide" :key="index1" @click="goArticle(index, index1)">{{ item }}</li>
            </ul>
          </div>
          <div class="servece-type-info">
            <div class="guanzhu-qr">
              <div class="guanzhu">{{ $t('f_00001') }}</div>
              <Image :src="require('@/assets/index/qrcode.png')" :fit="fit" width="140px" height="140px" :alt="fit"/>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="copyright">
          <p>Copyright © {{ year }} {{ config.company.name }}</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import { Image } from 'view-ui-plus'

export default {
  name: 'Footer',
  components: { Image },
  data() {
    return {
      config: require('@/config'),
      guideArr: [
        [this.$t('f_00004'), this.$t('f_00005'), this.$t('f_00006')],
        ['帮助与支持', '常见问题(FAQ)', '争议仲裁', '费用说明'],
        ['政策与隐私', '用户协议', '隐私政策', '商户入驻协议', '免责申明'],
      ],
      year: new Date().getFullYear(), // 当前年份
    }
  },
  methods: {
    goArticle(index, indexSecond) {
      if (index === 0) {
        this.$router.push({
          path: '/About',
          query: {
            id: indexSecond <= 1 ? 0 : 2
          }
        })
      } else if (index === 1) {
        this.$router.push({
          path: '/FooterHelp',
          query: {
            id: indexSecond
          }
        })
      } else {
        this.$router.push({
          path: '/Privacy',
          query: {
            id: indexSecond
          }
        })

      }
    },
  },
}
</script>

<style scoped lang="scss">
.guanzhu-kf {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;

  .guanzhu-kf-href {
    font-family: Source Han Sans;
    font-weight: 700;
    font-size: 18px;
    //color: #FFFFFF;
    margin-bottom: 30px;
  }

  .guanzhu-kf-hour {
    //color: #FFFFFF;
    margin-bottom: 10px;
  }

  Button {
    width: 280px;
  }
}

.guanzhu-qr {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  Image {
    width: 144px;
    height: 137px;
  }

  .guanzhu {
    margin-bottom: 10px;
    font-family: Source Han Sans;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    //color: #FFFFFF;
  }
}

.footer {
  width: 100%;
  height: 330px;
}

.icon-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1056px;
  height: 71px;
  margin: 0 auto;
}

.footer-icon {
  float: left;
}

.footer-icon-child {
  overflow: hidden;
  width: 36.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18.2px;
  font-weight: normal;
  //color: #FFFFFF;
  border: 1.4px solid #CECECE;
  height: 36.4px;
  border-radius: 50%;
}

.icp {
  margin-right: 30px !important;

  > * {
    margin: 0 4px;
  }

  flex-direction: column;
}

.flex {
  display: flex;
  align-items: center;

  > img {
    margin-right: 4px;
  }
}

.footer-icon-text {
  margin-left: 11px;
  font-size: 16px;

  font-weight: normal;

  text-align: center;
}

.service-intro {
  width: 100%;
  border-top: 1px solid #dddee1;
}

.servece-type {
  width: 1200px;
  margin: 22px auto 10px auto;
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.servece-type-info {
  margin-right: 100px;
  margin-bottom: 40px;
}

.servece-type-info ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  font-size: 18px;
}

.servece-type-info li {
  font-size: 14px;
  cursor: pointer;
  font-weight: normal;
  line-height: 15px;
  text-align: center;
  margin-bottom: 13px;
  //color: #FFFFFF;
}

.servece-type-info li:first-child {
  font-family: Source Han Sans;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  //color: #FFFFFF;
  margin-bottom: 17px;
}

.friend-link {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 908px;
  padding: 10px 0;
  margin: 0px auto;
  border-top: 1px solid #dddee1;
}

.information {
  margin-top: 23px;
  margin-bottom: 21px;
  display: flex;
  justify-content: center;

}

a {
  color: #3457FF;
}

.zhizhao {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.friend-link-item ul {
  list-style: none;
}

.friend-link-item li {
  padding: 5px 0px;
  float: left;
  margin-right: 59px;
  font-weight: normal;
  line-height: 13px;
  //color: #FFFFFF;
}


.link-item {
  padding: 0px 8px;
  cursor: pointer;

}

.link-last-item {
  border: none;
}

.copyright {
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  height: 48px;
  //background: #F4F4F4;
  //color: #333333;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;

  p {
    text-align: right;
    width: 1200px;

  }
}

.footer-icon-text {
  //color: #1c2438;
}

.copyright,
.friend-link,
.servece-type-info {
  //color: #333333;
}
</style>
