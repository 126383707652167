<template>
  <div class="item-class-show">
    <div class="d-flex mt-3 pl-1">
      <div class="text-gray text-df">您的当前位置:</div>
      <div class="mb-2 ml-1">
        <a-breadcrumb separator=">">
          <a-breadcrumb-item>
            <router-link :to="'/'">
              首页
            </router-link>
          </a-breadcrumb-item>
          <a-breadcrumb-item>
            <span class="text-df text-gray cursor-pointer" @click="cateClick(null,0)">
              所有交易分类
            </span>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-if="selectName1">
            <span class="text-df text-gray cursor-pointer" @click="cateItemClick(1)">
              {{ selectName1 }}
            </span>
          </a-breadcrumb-item>
          <a-breadcrumb-item v-if="selectName2">
            <span class="text-df text-gray cursor-pointer" @click="cateItemClick(2)">
              {{ selectName2 }}
            </span>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </div>
    <div class="head-bar-cus" v-if="selectName1 || selectName2">
      <div class="type-content">
        <a-card :bordered="false" :body-style="{padding: '10px 30px 0 30px'}">
          <div v-for="(item, index) in cateList" :key="index">
            <div v-if="select1 === item.id" class="border-btom-line">
              <div class="text-bold cursor-pointer" :class="select1 === item.id ? 'select-btn' : ''"
                   @click="cateClick(item,1)">
                {{
                  item.name
                }}
              </div>
              <div class="mt-2">
                <a-divider/>
              </div>
              <div class="d-flex mt-3 mb-3" v-for="(items, index) in item.childList" :key="index">
                <div class="flex justify-start align-center">
                  <div v-for="(itemsss, index) in items.childList" :key="index">
                    <div class="cursor-pointer mr-3 text-df"
                         :class="select2 === itemsss.id ? 'select-btn' : ''" type="link"
                         @click="cateClick(itemsss,2, item)">
                      {{
                        itemsss.name
                      }}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </a-card>

        <!--        <a-card>-->
        <!--          <a-row v-for="(item, index) in cateList" :key="index">-->
        <!--            <a-col :span="3" class="type-item">-->
        <!--              <a-button :class="select1 === item.id ? 'select-btn' : ''" type="link" @click="cateClick(item,1)">-->
        <!--                {{-->
        <!--                  item.name-->
        <!--                }}-->
        <!--              </a-button>-->
        <!--            </a-col>-->
        <!--            <a-col :span="21" class="type-class-item" v-for="(items, index) in item.childList" :key="index">-->
        <!--              <div class="flex justify-start align-center">-->
        <!--                <div v-for="(itemsss, index) in items.childList" :key="index">-->
        <!--                  <a-button :class="select2 === itemsss.id ? 'select-btn' : ''" type="link"-->
        <!--                            @click="cateClick(itemsss,2, item)">-->
        <!--                    {{-->
        <!--                      itemsss.name-->
        <!--                    }}-->
        <!--                  </a-button>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </a-col>-->
        <!--          </a-row>-->
        <!--        </a-card>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodsClassNav',
  props: {
    keywords: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      tabBar: { // 分类数据
        name: '',
        id: '',
        first: {},
        second: {}
      },
      showTagCount: 5, // 展示的搜索项数量
      multiple: false, // 多选
      tagsContent: [
        // 标签
        {
          key: '品牌',
          more: false,
          show: true,
          values: []
        }
      ],
      multSelected: [], // 多选分类
      selectedItem: [], // 已选分类集合 顶部展示
      brandIds: [], // 品牌id合集
      params: {}, // 请求参数
      select1: 0,
      select2: 0,
      selectName1: '',
      selectName2: '',
    }
  },
  computed: {
    cateList() { // 商品分类
      return this.$store.getters.merchantClassify || []
    }
  },
  watch: {
    selectedItem: {
      // 监听已选条件，来调用列表接口
      handler(val) {
        let classification = []
        this.params.brandId = ''
        this.params.prop = ''
        val.forEach((item) => {
          if (item.type === '品牌') {
            this.params.brandId = this.brandIds.join('@')
          } else {
            const nameArr = item.name.split('、')
            nameArr.forEach((name) => {
              classification.push(item.type + '_' + name)
            })
          }
        })
        this.params.prop = classification.join('@')
        this.$emit('getParams', this.params)
      },
      deep: true
    },
    '$route': { // 监听路由
      handler(val, oVal) {
        if (val.name !== 'GoodsList') {
          return
        }
        if (this.$route.query.keyword) {
          this.params.keyword = this.$route.query.keyword
        }
        if (this.$route.query.categoryId) {
          const cid = this.$route.query.cid
          const categoryId = this.$route.query.categoryId
          if (cid === categoryId) {
            this.params.cid = null
            this.params.name = null
          } else {
            this.params.cid = cid
            this.params.name = null
          }
          this.params.categoryId = categoryId
        } else {
          Object.assign(this.params, this.$route.query)
        }
        this.$emit('getParams', this.params)
        // this.getFilterList(this.params)
        this.getNav()
      },
      deep: true
    }
  },
  methods: {
    getNav() {
      // 获取商品分类，分类下展示
      if (!this.$route.query.categoryId) return
      if (!this.cateList.length) { // 商品分类存储在localstorage，接口未调用成功前再次刷新数据
        setTimeout(() => {
          this.getNav()
        }, 500)
        return
      }
      const name = this.$route.query.name
      if (name) {
        this.cateList.forEach(item => {
          if (item.name === name) {
            this.select1 = item.id
            this.selectName1 = item.name
          }
          if (item.childList.length > 0) {
            item.childList.forEach(item1 => {
              if (item1.childList.length > 0) {
                item1.childList.forEach(item2 => {
                  if (item2.name === name) {
                    this.select2 = item2.id
                    this.selectName2 = item2.name

                    this.select1 = item.id
                    this.selectName1 = item.name
                  }
                })
              }
            })
          }
        })
      }
    },
    cateItemClick(index) {
      switch (index) {
        case 1:
          this.params.cid = ''
          this.params.categoryId = this.select1
          this.select2 = ''
          this.selectName2 = null
          this.$emit('getParams', this.params)
          break
        case 2:
          this.params.cid = this.select1
          this.params.categoryId = this.select2
          this.$emit('getParams', this.params)
          break
      }
    },
    cateClick(item, index, items) { // 点选分类
      switch (index) {
        case 0:
          this.select1 = 0
          this.select2 = 0
          this.selectName1 = null
          this.selectName2 = null
          this.params.cid = ''
          this.params.categoryId = ''
          this.$emit('getParams', this.params)
          break
        case 1:
          this.params.cid = ''
          this.params.categoryId = item.id
          this.select1 = item.id
          this.select2 = ''
          this.selectName1 = item.name
          this.selectName2 = null
          this.$emit('getParams', this.params)
          break
        case 2:
          this.select1 = items.id
          this.select2 = item.id

          this.selectName1 = items.name
          this.selectName2 = item.name

          this.params.cid = item.id
          this.params.categoryId = items.id
          this.$emit('getParams', this.params)
          break
      }
    },
    cancel() {
      // 多选取消按钮
      this.multSelected = []
      this.tagsContent[0].more = false
      this.multiple = false
    },
  },
  mounted() {
    // 有分类id就根据id搜索
    if (this.$route.query.categoryId) {
      let cateId = this.$route.query.categoryId.split(',')
      Object.assign(this.params, this.$route.query)
      this.params.categoryId = cateId[cateId.length - 1]
    } else {
      Object.assign(this.params, this.$route.query)
    }
    this.getNav()
  }
}
</script>

<style scoped lang="scss">
.ant-divider-horizontal {
  margin: 0 !important;
}

.select-btn {
  color: #2b85e4;
  font-weight: 500;
}

.select-all {
  font-weight: 500;
}

.type-content {
  width: 100%;
  margin-bottom: 10px;
  //border: 1px solid #DDDDDD;

  .type-class-item {
    //border-bottom: 1px solid #DDDDDD;
    flex: 1;
  }

  .type-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    //border-bottom: 1px solid #DDDDDD;
    //border-left: 1px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;

    &:first-child {
      padding: 0 8px;
      font-size: 12px;
      font-weight: bold;

      &:hover {
        //color: #269FFA;
        cursor: pointer;
      }
    }
  }

}

/** 头部展示筛选项 */
.head-bar-cus {
  width: 100%;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .bar {
    font-size: 12px;
    position: relative;
    //background: #fff;
    border: 1px solid #999;
    padding: 0 8px;
    min-width: 85px;
    text-align: center;
    margin: 0 3px;

    &:hover {
      color: #269FFA;
      border-color: #269FFA;
      border-bottom-color: #fff;
      cursor: pointer;

      ul {
        display: block;
      }

      .ivu-icon {
        transform: rotate(180deg);
      }
    }

    ul {
      display: none;
      position: absolute;
      top: 18px;
      left: -1px;
      width: 300px;
      padding: 5px 10px;
      background: #fff;
      border: 1px solid #269FFA;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        width: 83px;
        left: 0;
        top: -1px;
        z-index: 2;
        border-top: 1px solid #fff;
      }

      &:hover {
        display: block;
      }

      clear: left;

      li {
        color: #999;
        float: left;
        width: 30%;
        margin: 3px 0;
        text-align: left;

        &:hover {
          color: #269FFA;
          cursor: pointer;
        }
      }
    }
  }

  //所选分类
  .selected-item {
    font-size: 12px;
    color: #000;
    padding: 2px 22px 2px 8px;
    margin-right: 5px;
    max-width: 250px;
    height: 24px;
    overflow: hidden;
    position: relative;
    background-color: #f3f3f3;
    border: 1px solid #ddd;

    &:hover {
      border-color: #269FFA;
      background-color: #fff;

      .ivu-icon {
        color: #fff;
        background-color: #269FFA;
      }
    }

    span:nth-child(2) {
      color: #269FFA;
    }

    .ivu-icon {
      position: absolute;
      right: 0;
      top: 0;
      color: #269FFA;
      line-height: 22px;
      width: 21px;
      height: 22px;
      font-size: 14px;
    }
  }
}

/** 筛选主体 */
.content {
  background: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
}

/** 品牌 start */
.brand {
  border-bottom: 1px solid #ddd;
  display: flex;
  // min-height: 120px;
  font-size: 12px;

  > div:first-child {
    width: 100px;
    background: #eee;
    padding: 10px 0 0 10px;
  }

  > div:last-child {
    width: 1100px;
    padding: 10px;
    position: relative;

    ul {
      width: 900px;
      max-height: 100px;
      overflow: hidden;
      padding-top: 1px;
      clear: left;

      li {
        width: 100px;
        height: 50px;
        float: left;
        line-height: 45px;
        border: 1px solid #ddd;
        margin: -1px -1px 0 0;
        overflow: hidden;
        position: relative;
        padding: 2px;

        img {
          width: 100%;
          height: 100%;
        }

        &:hover {
          border-color: #269FFA;
          border: 2px solid #269FFA;
          top: 0;
          left: 0;
          position: relative;
          z-index: 1;

          img {
            display: none;
          }
        }

        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          color: #269FFA;
          text-align: center;
          font-size: 12px;
          cursor: pointer;
        }

        .corner-icon {
          position: absolute;
          right: -1px;
          bottom: -1px;

          div {
            width: 0;
            border-top: 20px solid transparent;
            border-right: 20px solid #269FFA;
          }

          .ivu-icon {
            font-size: 12px;
            position: absolute;
            bottom: 0;
            right: 1px;
            transform: rotate(-15deg);
            color: #fff;
          }
        }
      }

      .border-color {
        border-color: #269FFA;
        z-index: 1;
      }
    }

    .show-more {
      height: auto;
      max-height: 200px;
      overflow: scroll;
    }

    .btn {
      position: absolute;
      right: 10px;
      top: 10px;

      span {
        border: 1px solid #ddd;
        margin-left: 10px;
        color: #999;
        display: inline-block;
        padding: 1px 3px;
        font-size: 12px;

        &:hover {
          cursor: pointer;
          color: #269FFA;
          border-color: #269FFA;
        }
      }
    }

    .multBtn {
      text-align: center;
      margin-top: 10px;

      .ivu-btn {
        font-size: 12px !important;
      }

      .ivu-btn:last-child {
        margin-left: 10px;
      }
    }
  }
}

/** 品牌 end */
/** 其他筛选项  start */
.other {
  border-bottom: 1px solid #ddd;
  display: flex;
  min-height: 30px;
  font-size: 12px;

  &:last-child {
    border: none;
  }

  > div:first-child {
    width: 100px;
    background: #eee;
    padding-left: 10px;
    line-height: 30px;
  }

  > div:last-child {
    width: 1100px;
    padding: 0 10px;
    position: relative;

    .list {
      width: 900px;
      height: 30px;
      overflow: hidden;
      clear: left;

      .item {
        width: 100px;
        height: 30px;
        float: left;
        line-height: 30px;
        color: #4d9cf1;
        overflow: hidden;
        position: relative;
        font-size: 12px;
        padding: 2px;
        cursor: pointer;

        &:hover {
          color: #269FFA;
        }
      }
    }

    .show-more {
      height: auto;
    }

    .btn {
      position: absolute;
      right: 10px;
      top: 5px;

      span {
        border: 1px solid #ddd;
        margin-left: 10px;
        color: #999;
        display: inline-block;
        padding: 1px 3px;
        font-size: 12px;

        &:hover {
          cursor: pointer;
          color: #269FFA;
          border-color: #269FFA;
        }
      }
    }

    .multBtn {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;

      .ivu-btn {
        font-size: 12px !important;
      }

      .ivu-btn:last-child {
        margin-left: 10px;
      }
    }
  }
}

.more-options {
  margin: 5px;
  color: #4d9cf1;
  font-size: 12px;
  cursor: pointer;
  text-align: right;
}

.more-options:hover {
  color: #0165d1;
}

/** 其他筛选项  end */
</style>
