<template>
  <div class="cate-nav width_1200_auto" :class="{'fixed-show':useClass === 'fixed-show'}">
    <div class="nav-con" :class="{'background-white':useClass === 'background-white'}">
      <div
          class="hover-pointer"
          :class="showNavBar ? 'all-categories' : 'all-categories-fixed'"
          @mouseenter="showFirstLists"
          @mouseleave="hideFirstLists"
          @click="showTab = !showTab">
        <Icon type="ios-list" size="22"/>
        {{ $t('nav_0000') }}
      </div>
      <ul class="nav-item " v-if="showNavBar">
        <li
            class="nav-lis flex flex-a-c flex-j-c"
            v-for="(item, index) in navList"
            :key="index">
          <a-button type="link" @click="toRouter(item.url)">
            <div class="cu-center">
            <span v-if="item.icon" class="yaohuang">
              <a-image :preview="false" :height="26" :src="require('@/assets/index/coup.png')"></a-image>
            </span>
              <span class="top-btn-font" :class="item.icon ? 'text-red yaohuang-tu' :'' ">{{ item.name }}</span>
            </div>
          </a-button>
        </li>
      </ul>
    </div>
    <!-- 全部商品分类 -->
    <div
        class="cate-list"
        v-show="showTab"
        @mouseenter="showFirstList = true;isTouch = true"
        @mouseleave="showFirstList = false;showTab = false;isTouch = false">
      <!-- 第一级分类 -->
      <div
          class="nav-side"
          :class="{ 'large-nav': large, 'opacity-nav': opacity }"
          @mouseleave="panel = false">
        <ul>
          <li v-for="(item, index) in cateList"
              :key="index"
              class="cursor-pointer"
              @click="goGoodsList(item)"
              @mouseenter="showDetail(index)">
            <span class="nav-side-item" @click="goGoodsList(item)">{{
                item.name
              }}</span>
          </li>
        </ul>
      </div>

      <!-- 展开分类 -->
      <div class="detail-item-panel"
           :style="{ minHeight: large ? '420px' : '420px' }"
           v-show="panel"
           @mouseenter="panel = true"
           @mouseleave="panel = false;">
        <ul>
          <li v-for="(items, index) in panelData" :key="index">
            <span class="text-bold">{{ items.name }}</span>
            <div class="line-d mb-1"/>
            <div>
              <span v-for="(item, subIndex) in items.childList"
                    @click="goGoodsList(item)"
                    :key="subIndex"
                    class="detail-item text-bold">{{ item.name }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoodsListNav',
  props: {
    showAlways: {
      // 总是显示下拉分类
      default: false,
      type: Boolean,
    },
    showNavBar: {
      // 显示全部商品分类右侧导航条
      default: true,
      type: Boolean,
    },
    hover: {
      default: false,
      type: Boolean,
    },
    large: {
      // 是否更高的高度
      default: false,
      type: Boolean,
    },
    opacity: {
      // 是否背景透明
      default: false,
      type: Boolean,
    },
    useClass: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      showTab: false,
      panel: false, // 二级分类展示
      panelData: [], // 二级分类数据
      showFirstList: false, // 始终展示一级列表
      isTouch: false,
      cateList: [], // 商品分类
      navList: [{
        name: this.$t('nav_0001'),
        url: '/'
      }, {
        name: this.$t('nav_0002'),
        url: '/Guarantee'
      }, {
        name: this.$t('nav_0003'),
        url: '/Enter'
      }, {
        name: this.$t('nav_0004'),
        url: '/Share'
      }, {
        name: this.$t('nav_0005'),
        url: '/Supply'
      },
        {
          name: this.$t('nav_0006'),
          url: '/Partner'
        },
        {
          name: this.$t('nav_0007'),
          url: '/Infor'
        }
      ],
      checked: {}
    }
  },
  watch: {
    '$route.name': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (newVal !== 'IndexPage') {
            this.showTab = false
          } else {
            this.showTab = true
          }
        })
      }
    }
  },
  methods: {
    toRouter(pathUrl) {
      this.showTab = true
      if (pathUrl !== '/') {
        this.showTab = false
      }
      this.$router.push({
        path: pathUrl,
      })
    },
    showFirstLists() {
      this.showFirstList = true
      this.showTab = true
    },
    hideFirstLists() {
      setTimeout(() => {
        if (!this.isTouch) {
          this.showTab = false
        }
      }, 50)
    },
    showDetail(index) {
      // 展示全部分类
      this.panel = true
      this.checked = this.cateList[index]
      this.panelData = this.checked.childList
    },
    goGoodsList(item) {
      if (this.$route.name !== 'IndexPage') {
        this.showTab = false
      } else {
        this.showTab = true
      }
      let categoryId = this.checked.id
      let cid = item.id
      if (categoryId === cid) {
        cid = null
      }
      let name = item.name
      this.$router.push({
        path: '/goodsList',
        query: {
          categoryId: categoryId,
          cid: cid,
          name: name,
        },
      })
    },
    getCate() {
      // 获取分类数据
      if (this.hover) return false
      this.cateList = JSON.parse(localStorage.getItem('merchantClassify'))
    },
  },
  mounted() {
    const merchantClassify = localStorage.getItem('merchantClassify')
    if (!merchantClassify) {
      this.$store.dispatch('MerCategoryList')
      this.$store.dispatch('MerTypeList')
      setTimeout(() => {
        this.getCate()
      }, 500)
    } else {
      this.getCate()
    }
  },
}
</script>

<style scoped lang="scss">
.line-d {
  height: 3px;
  border-bottom: 1px solid #ccc;
}

.yaohuang {
  animation: fadeEffect 3s infinite;
}

.yaohuang-tu {
  animation: zy 2.5s .15s linear infinite;
}

/* 定义淡入淡出动画 */
@keyframes fadeEffect {
  0%, 100% {
    height: 46px;
    opacity: 0.5; /* 完全透明 */
  }
  50% {
    height: 26px;
    opacity: 1; /* 完全不透明 */
  }
}

@-webkit-keyframes zy {
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%, 100% {
    transform: rotate(0deg);
  }
}

.top-btn-font {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0em;
}

.background-white {
  background: #fff;
}

.nav-lis:hover {
  color: #269FFA !important;
  cursor: pointer;
}

.nav-lis {
  text-align: center;
  position: relative;
}

.nav-side li:hover {
  color: #FFFFFF !important;
  background-color: #269FFA !important;
}

.fixed-show {
  margin-top: 0 !important;

  > .nav-con {
    > .all-categories {
      align-items: center !important;
      height: 60px;
      display: inherit;
      justify-content: center;
      padding: 0 !important;
    }
  }
}

.colum {
  height: 14.7px;
  opacity: 1;
  border: 0.7px solid #CBC8C8;
  position: absolute;
  right: 0;
}

.cate-nav {
  position: relative;
}

/** 商品分类 */
.nav-con {
  height: 42px;
  margin: 0 auto;
  display: flex;

  .all-categories-fixed {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 226px;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    background: #269FFA;
    color: #FFFFFF;
  }

  .all-categories {
    display: flex;
    padding-left: 20px;
    justify-content: flex-start;
    align-items: center;

    background: #269FFA;
    width: 226px;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    color: #FFFFFF;
  }

  .nav-item {
    height: 42px;
    line-height: 42px;
    overflow: hidden;
    list-style: none;
    // background-color: #eee;
    display: flex;
    flex: 1;

    li {
      font-size: 17px;
      font-weight: normal;


      &:hover {
        color: #269FFA;
      }
    }
  }
}

// 分类列表
.cate-list {
  width: 226px;
  margin: 0 auto;
  position: absolute;
  z-index: 1000;
}

.nav-side {
  float: left;
  overflow: hidden;
  width: 226px;
  height: 420px;
  opacity: 1;
  background: rgba(0, 0, 0, 0.75);
  /* 阴影/常规阴影 */
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
}

.large-nav {
  height: 420px;

  ul > li {
    line-height: 20px;
  }
}

.opacity-nav {
  //background-color: rgba(0, 0, 0, 0.5);
}

.nav-side ul {
  width: 100%;
  list-style: none;
}

.nav-side li {
  padding: 2px 0 0 37.4px;
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
}

.nav-side-item:hover {
  cursor: pointer;
  color: #FFFFFF;
}

/*显示商品详细信息*/
.detail-item-panel {
  width: 550px;
  min-height: 418px;
  position: absolute;
  /* 阴影/常规阴影 */
  top: 0;
  opacity: 0.9;
  left: 226px;
  z-index: 1000;
  padding: 15px;

  border-right: 1px solid #269FFA !important;
  border-top: 1px solid #269FFA !important;
  border-bottom: 1px solid #269FFA !important;
}

.nav-detail-item {
  margin-top: 5px;
  margin-bottom: 15px;
  cursor: pointer;
}

.nav-detail-item span {
  padding: 6px;
  padding-left: 12px;
  margin-right: 15px;
  font-size: 12px;
  color: #333;
}

.nav-detail-item span:hover {
  background-color: #269FFA;
}

.detail-item-panel li {
  line-height: 30px;
}

.detail-item-title {
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #555555;
  padding-right: 10px;
  width: 96px;
  text-align: right;
}

.detail-item-title:hover {
  color: #269FFA;
}

.detail-item-row {
  display: flex;

  > div {
    flex: 1;
  }
}

.detail-item {
  font-size: 13px;
  padding-left: 8px;
  padding-right: 8px;
  cursor: pointer;
  //border-left: 1px solid #ccc;

  &:first-child {
    border: none;
    padding-left: 0;
  }
}

.detail-item:hover {
  color: #269FFA;
}
</style>
