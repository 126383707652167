<template>
  <div>
    <div class="zouma">
      <a-carousel style="height: 420px;" :autoplay="true">
        <div class="car-item">
          <a-image :preview="false" :height="420" :src="require('@/assets/index/banner1.png')"></a-image>
        </div>
        <div class="car-item">
          <a-image :preview="false" :height="420" :src="require('@/assets/index/banner2.png')"></a-image>
        </div>
        <div class="car-item">
          <a-image :preview="false" :height="420" :src="require('@/assets/index/banner3.png')"></a-image>
        </div>
      </a-carousel>
      <div class="car-right">
        <div class="right-msg">
          <div class="msg-content">
            <div class="top-login">
              <div class="avatar">
                <a-avatar v-if="!userInfo.nickname" :src="require('@/assets/index/ava.png')" :size="42"></a-avatar>
                <a-avatar v-if="userInfo.nickname" :src="userInfo.avatar" :size="42">
                  <template #icon>
                    <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
                  </template>
                </a-avatar>
              </div>
              <div>
                <div class="welcome">欢迎来到51担保网！</div>
                <div v-if="!userInfo.nickname">
                  <span class="login-btn" @click="toLogin">登录</span>
                  <span><a-divider style="border-color: #269FFA" type="vertical"></a-divider></span>
                  <span class="login-btn" @click="toRegister">注册</span>
                </div>
                <div v-else>
                <span class="login-btn" @click="toUserInfo">
												{{ userInfo.email ? userInfo.email : userInfo.phone ? userInfo.phone : '' }}
                </span>
                </div>
              </div>
            </div>
            <div class="msg-content-bottom">
              <div class="message">
                <a-divider style="border-color: #269FFA;" type="vertical"></a-divider>
                公告消息
              </div>
              <Scroll :height="250">
                <div class="msg-cell cursor-pointer" v-for="(item, index) in dataMsg" :key="index"
                     @click="() => {$router.push({path:'/Notice', query:{id: item.id }})}">
                  <Icon type="md-star-outline" :size="18" color="#269FFA"/>
                  <span class="text-hover">{{ item.title }}</span>
                </div>
              </Scroll>
              <div class="d-flex flex-wrap">
                <div class="cu-center cursor-pointer flex-1-0 solid" style="width: 50%;height: 42px">
                  TRX兑换
                </div>
                <div class="cu-center cursor-pointer solid" style="width: 50%;height: 42px">
<!--                  chatGPT-->
                </div>
                <div class="cu-center cursor-pointer flex-1-0 solid" style="width: 50%;height: 42px"
                     @click="$router.push({path:'/AddSupply'})">
                  供需发布
                </div>
                <div class="cu-center cursor-pointer solid" style="width: 50%;height: 42px"
                     @click="toInfors">
                  曝光资讯
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="model-content">
      <div class="model-content-top">
        <div class="top-font">
          <a-tabs v-model:activeKey="activeKey" @change="changeActive">
            <a-tab-pane key="1">
              <template #tab>
          <span>
           <a-image :preview="false" :height="20" :width="15" :src="require('@/assets/index/hot1.png')"
                    :fallback="require('@/assets/index/logo.png')"/>
          <span class="tbs-font">热门商铺</span>
          </span>
              </template>
            </a-tab-pane>
            <a-tab-pane key="2">
              <template #tab>
          <span>
           <a-image :preview="false" :height="13" :width="30" :src="require('@/assets/index/new1.png')"
                    :fallback="require('@/assets/index/logo.png')"/>
            <span class="tbs-font">新增商铺</span>
          </span>
              </template>
            </a-tab-pane>
          </a-tabs>
        </div>
        <div class="top-font-right cursor-pointer" @click="toGoodsList">
          更多
          <Icon :size="16" type="ios-arrow-forward"/>
        </div>
      </div>
      <div>
        <a-row :gutter="16">
          <a-col class="mer-card" v-if="loading" :span="8" v-for="index in 9" :key="index">
            <a-skeleton active :loading="loading" avatar :paragraph="{ rows: 4 }"/>
          </a-col>
          <a-col v-if="!loading" class="mer-card" :span="8" v-for="(item, index) in merList" :key="index">
            <a-card :loading="loading" :bordered="false" :bodyStyle="{padding: '10px'}">
              <div class="mer-card-content">
                <div class="mer-card-title cursor-pointer"
                     @click="()=>{$router.push({path:'/MerHome', query:{merId: item.id}})}">
                  <div class="avatar">
                    <a-avatar :src="item.avatar" :size="42"></a-avatar>
                  </div>
                  <div>
                    <div class="mer-name line1">{{ item.name }}</div>
<!--                    <div class="mer-level">-->
<!--                      店铺等级:-->
<!--                      <a-rate v-model:value="item.starLevel" disabled>-->
<!--                        <template #character="{ index }">-->
<!--                          <a-image v-if="index < item.starLevel" :preview="false" :width="15" :height="12"-->
<!--                                   :src="require('@/assets/index/start.png')"/>-->
<!--                          <a-image v-else :preview="false" :width="15" :height="12"-->
<!--                                   :src="require('@/assets/index/start_v.png')"/>-->
<!--                        </template>-->
<!--                      </a-rate>-->
<!--                    </div>-->
                    <div class="custom-font">保证金：{{ item.earnestMoney }} USDT</div>
                  </div>
                </div>

                <div class="mer-card-content sroll-product" id="nav">
                  <arco-scrollbar type="embed" style="height:120px;overflow: auto;">
                    <div class="flex">
                      <div v-for="(items, index) in item.proList" :key="index" class="cursor-pointer"
                           @click="() => {$router.push({path:'/Product', query:{ id: items.id }})}">
                        <div class="imgs">
                          <a-image :preview="false" :src="items.image" :width="109" :height="106"
                                   style="border-radius: 10px;"
                                   :fallback="require('@/assets/index/logo.png')"/>
                          <div class="usdt-bottom">
                            {{ items.price }}USDT
                          </div>
                        </div>
                      </div>
                    </div>
                  </arco-scrollbar>
                </div>
              </div>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <!-- 担保 -->
      <div class="danbao-content">
        <div class="danbao-content-top">
          <div class="top-font">51担保交易</div>
          <div class="top-font-right cursor-pointer" @click="toGuarantee">
            更多
            <Icon :size="16" type="ios-arrow-forward"/>
          </div>
        </div>
        <div class="danbao-list">
          <a-card :bodyStyle="{padding: '10px'}">
            <a-row :gutter="[16,24]">
              <a-col :span="6" v-for="(item, index) in cusList" :key="index">
                <a-card :loading="loadingSke" :bordered="false" :bodyStyle="{padding: '0px'}">
                  <div class="danbao-cell">
                    <List>
                      <ListItem>
                        <ListItemMeta description="">
                          <template #avatar>
                            <a-avatar :size="48" :src="item.headImg"/>
                          </template>
                          <template #title>
                            <div class="danbao-cell-title">{{ item.userName }}</div>
                          </template>
                          <template #description>
<!--                            <div class="danbao-cell-description">擅长:{{ item.typeName }}</div>-->
                            <div class="danbao-cell-btns">
                              <div class="mr-4 danbao-cell-btn1 cursor-pointer"
                                   @click="openLink(item.tgUrl)">
                                交谈
                              </div>
                              <div class="danbao-cell-btn2 cursor-pointer" @click="sendTg(item.typeId)"
                              >
                                发起担保
                              </div>
                            </div>
                          </template>
                        </ListItemMeta>
                      </ListItem>
                    </List>
                  </div>
                </a-card>
              </a-col>
            </a-row>
          </a-card>
        </div>
      </div>

      <!-- 供需 -->
      <div class="danbao-content">
        <div class="danbao-content-top">
          <div class="top-font">
            <a-tabs v-model:activeKey="activeGxKey" @change="changeGxActive">
              <a-tab-pane key="1">
                <template #tab>
                  <span class="tbs-font">供应</span>
                </template>
              </a-tab-pane>
              <a-tab-pane key="2">
                <template #tab>
                  <span class="tbs-font">需求</span>
                </template>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="top-font-right cursor-pointer" @click="toGongxu">
            更多
            <Icon :size="16" type="ios-arrow-forward"/>
          </div>
        </div>

        <empty v-if="noteRecommendList.length === 0"/>

        <div class="gongxu-list" v-else>
          <a-card :bodyStyle="{padding: '10px'}">
            <a-row :gutter="[16,24]">
              <a-col class="cursor-pointer" :span="8" v-for="(item, index) in noteRecommendList" :key="index"
                     @click="toGongxuInfo(item)">
                <div class="gongxu-cell">
                  <div class="gongxu-item">
                    <div class="title-tg">{{ activeGxKey === '1' ? '供应' : '需求' }}</div>
                    <div class="title text-no-wrap">{{ item.title }}</div>
                  </div>
                  <div class="gongxu-info">{{ item.content }}</div>
                </div>
              </a-col>
            </a-row>
          </a-card>
        </div>

      </div>

      <!-- 资讯曝光 -->
      <div class="danbao-content">
        <div class="danbao-content-top">
          <div class="top-font">曝光、资讯</div>
          <div class="top-font-right cursor-pointer" @click="toInfors">
            更多
            <Icon :size="16" type="ios-arrow-forward"/>
          </div>
        </div>
        <div class="danbao-list">
          <a-card :bodyStyle="{padding: '0', paddingTop: '10px'}">
            <div class="d-flex">
              <div class="me-auto" style="width: 56%;border-right: 1px solid #D8D8D8">
                <div class="d-flex justify-between mt-2 padding-right-xs" v-for="(item ,index) in inforList"
                     :key="index">
                  <div class="text-cut pl-3" style="width: 70%">
                    <a href="javascript:void(0);" class="text-black hover-color-cus" @click="toInforDetails(item)">·
                      {{ item.contentInfo }}</a>
                  </div>
                  <div>
                    {{ item.createTime }}
                  </div>
                </div>
                <div class="mt-3 mb-3">
                  <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                                :show-size-changer="false"
                                :size="'small'"
                                @page-size-change="changePageSize"/>
                </div>
              </div>
              <div style="flex:1;">
                <div class="ml-2 cursor-pointer">
                  <a-row :gutter="[16,24]">
                    <a-col :span="12" v-for="(item ,index) in imgs" :key="index">
                      <a-image :preview="false" :width="240" :height="100" :src="item.url"
                               class="img-fit"
                               @click="toInforDetails(item)"></a-image>
                    </a-col>
                  </a-row>
                </div>
                <div class="mt-2 ml-2">
                  <div class=" flex-title">
                    <div class="line-shu"></div>
                    <span class="right-title ml-2">热门、曝光、资讯排行</span>
                  </div>
                  <div class="text-cut mt-2 mb-3" style="width: 400px;" v-for="(item ,index) in hots" :key="index">
                    <a href="javascript:void(0);" class="text-black hover-color-cus" @click="toInforDetails(item)">·
                      {{ item.contentInfo }}</a>
                  </div>
                </div>
              </div>
            </div>
          </a-card>
        </div>
      </div>

      <div class="mt-5 mb-6 position-relative">
        <a-image :width="1200" :preview="false" :src="require('@/assets/index/bo_banner.png')"
                 :fallback="require('@/assets/index/logo.png')"></a-image>
        <div class="position-absolute img-font">
          <div class="font-1">
            <span>交易有风险</span> <span class="ml-5">就来51担保</span>
          </div>
          <div class="font-2 mt-6">
            <span>线上交易</span> <span class="ml-5">安全无忧</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Search from '@/components/Search.vue'
import cateNav from '@/components/nav/CateNav.vue'

import { getArticleList } from '@/api/api'
import { getDiyMerListApi, getDiyMerListReverApi } from '@/api/merchant'

import { getRandomOne, queryCusList } from '@/api/tg.js'
import { discoverListApi } from '@/api/discover.js'
import { SmileTwoTone } from '@ant-design/icons-vue'

import { inforList, } from '@/api/infor.js'

export default {
  name: 'HomePage',
  components: {
    SmileTwoTone,
    Search,
    cateNav
  },
  data() {
    return {
      userInfo: {}, // 用户信息
      dataMsg: [],
      activeKey: '1',
      activeGxKey: '1',
      merList: [],
      numConfig: 9,
      loading: false,
      cusList: [],
      loadingSke: false,
      noteRecommendList: [],
      page: 1,
      limit: 9,
      total: 0,
      inforList: [],
      imgs: [],
      hots: []
    }
  },
  watch: {
    '$store.getters.token': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          if (!newVal) {
            this.userInfo = { nickname: '' }
          }
        })
      }
    }
  },
  created() {
    this.getCidArticle()
    this.getMerList()
    this.getDbCusList()
    this.getDiscoverList()
    this.infos()
    this.infoHots()

    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
    }
  },
  methods: {
    toInforDetails(item) {
      this.$router.push({
        path: '/Details',
        query: { inforId: item.id }
      })
    },
    infos() {
      let data = {
        page: this.page,
        limit: this.limit,
        itemId: 1
      }
      inforList(data)
          .then((res) => {
            let curPageData = res.data.list
            this.total = res.data.total
            this.inforList = curPageData //追加新数据
            this.inforList.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
                item.img.forEach(itemImg => {
                  if (this.imgs.length <= 3) {
                    this.imgs.push({
                      id: item.id,
                      url: itemImg
                    })
                  }
                })
              }
            })
          })
    },
    infoHots() {
      let data = {
        page: 1,
        limit: 3,
        itemId: 0
      }
      inforList(data)
          .then((res) => {
            this.hots = res.data.list
            this.hots.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
              }
            })
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.page = val
      this.infos()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.page = 1
      this.limit = val
      this.infos()
    },
    toInfors() {
      this.$router.push({
        path: '/Infor'
      })
    },
    toGoodsList() {
      this.$router.push({
        path: '/GoodsList',
        query: {
          keyword: '',
          selectFlag: '1',
        },
      })
    },
    sendTg(typeId) {
      if (this.$store.getters.token) {
        this.getRandomOneQuery(typeId)
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    getRandomOneQuery(typeId) {
      getRandomOne(typeId)
          .then((res) => {
            if (res.code === 200) {
              this.$router.push({
                path: '/Guarantee',
                query: {
                  id: typeId,
                }
              })
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    toGuarantee() {
      this.$router.push({
        path: '/Guarantee',
        query: {}
      })
    },
    openLink(url) {
      if (this.$store.getters.token) {
        window.open(url)
      } else {
        this.$router.push({
          path: '/Login',
          query: {}
        })
      }
    },
    toGongxu() {
      this.$router.push({
        name: 'Supply',
        query: {}
      })
    },
    toGongxuInfo(item) {
      this.$router.push({
        path: '/SupplyInfo',
        query: {
          id: item.id,
        }
      })
    },
    toUserInfo() {
      this.$router.push({
        name: 'UserHome',
        query: {}
      })
    },
    //发现列表
    getDiscoverList() {
      this.where = {
        page: 1,
        limit: 9,
        categoryId: this.activeGxKey,
      }
      discoverListApi(this.where)
          .then(res => {
            this.noteRecommendList = res.data.list
          })
    },
    getDbCusList() {
      let data = {
        page: 1,
        limit: 12
      }
      this.loadingSke = true
      queryCusList(data)
          .then((res) => {
            this.cusList = res.data.list
            this.loadingSke = false
          })
    },
    load({ done }) {
      done('empty')
    },
    changeGxActive(event) {
      this.activeGxKey = event
      this.getDiscoverList()
    },
    changeActive(event) {
      if (event === '1') {
        this.getMerList()
      } else {
        this.getMerListRever()
      }
    },
    //默认店铺数据
    getMerList() {
      this.loading = true
      getDiyMerListApi(this.numConfig)
          .then((res) => {
            this.merList = res.data.list
            this.loading = false
          })
    },
    getMerListRever() {
      this.loading = true
      getDiyMerListReverApi(this.numConfig)
          .then((res) => {
            this.merList = res.data.list
            this.loading = false
          })
    },
    getCidArticle() {
      const data1 = {
        page: 1,
        limit: 1000
      }
      getArticleList(1, data1)
          .then(res => {
            this.dataMsg = res.data.list
          })
    },
    toLogin() {
      this.$router.push({
        path: '/login',
        query: {}
      })
    },
    toRegister() {
      this.$router.push({
        path: '/signUp',
        query: {}
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.hover-color-cus:hover {
  color: #2b85e4 !important;
  cursor: pointer;
}

.right-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  letter-spacing: 0px;
  color: #3D3D3D;
}

.zouma {
  position: relative;
  width: 100%;
  height: 420px;

  .car-right {
    position: absolute;
    width: 1200px;
    height: 420px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .car-item {
      display: flex;
      justify-content: end;
      width: 1920px;
      height: 420px;
    }
  }
}

:deep(.slick-slide) {
  text-align: center;
  width: 1920px;
  height: 420px;
  line-height: 160px;
  background: #F4F4F4;
  overflow: hidden;
}

.img-font {
  right: 20px;
  top: 30px;

  .font-1 {
    font-size: 42px;
    font-weight: 900;
    line-height: normal;
    letter-spacing: -0.17px;

    background: linear-gradient(270deg, #26DEFA 0%, #269FFA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .font-2 {
    font-size: 24px;
    font-weight: normal;
    line-height: 0px;
    text-align: center;
    letter-spacing: 0.2em;
    color: #666666;
  }
}

.text-hover {
  text-decoration: none;
}

.text-hover:hover {
  opacity: 1;
  text-decoration: underline;
  text-decoration-color: #2b85e4;
  color: #2b85e4;
}

.danbao-content {
  margin-top: 10px;

  .gongxu-list {
    margin-top: 10px;

    .gongxu-cell {
      padding: 10px;
      border: 1px solid #D8D8D8;
      border-radius: 12px;
      height: 78px;

      .gongxu-info {
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0em;
        color: #777777;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .gongxu-item {
        width: 100%;
        display: flex;

        .title-tg {
          width: 42px;
          height: 22px;
          border-radius: 100px;
          background: #FF6600;
          text-align: center;
          color: #fff;
          margin-right: 15px;
        }

        .title {
          text-align: left;
          font-size: 14px;
          font-weight: bold;
          line-height: 25px;
          letter-spacing: 0px;
        }
      }
    }
  }

  .danbao-list {
    margin-top: 10px;

    .danbao-cell {
      padding: 10px;

      &-btns {
        display: flex;
      }


      &-btn1 {
        width: 68px;
        height: 20px;
        border-radius: 100px;
        opacity: 1;
        box-sizing: border-box;
        border: 1px solid #FF6600;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FF6600;
      }

      &-btn2 {
        width: 68px;
        height: 20px;
        border-radius: 100px;
        opacity: 1;
        box-sizing: border-box;
        border: 1px solid #269FFA;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #269FFA;
      }

      &-title {
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
      }

      &-description {
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        color: #269FFA;
        margin-bottom: 10px;
      }

    }
  }

  .danbao-content-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .top-font {
      font-size: 18px;
      font-weight: bold;
      line-height: normal;
    }

    .top-font-right {
      font-size: 14px;
      font-weight: 350;
      line-height: 26px;
      letter-spacing: 0em;
      color: #999999;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.mer-card-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 190px;

  .sroll-product {
    margin-top: 10px;

    .imgs {
      margin-right: 15px;
      position: relative;

      .usdt-bottom {
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 24px;
        background: rgba(0, 0, 0, 0.5);
        text-align: center;

        font-size: 12px;
        font-weight: 900;
        line-height: 26px;

        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

:deep(.v-infinite-scroll__side) {
  padding: 3px !important;
}

.v-infinite-scroll--horizontal {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.v-infinite-scroll--horizontal:hover {
  overflow-x: scroll !important;
}

.custom-font {
  font-size: 13px;
  color: #FF6600;
}

.mer-card-title {
  height: 68px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  .mer-level {
    font-size: 13px;
    color: #269FFA;
  }

  .mer-name {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0px;
  }

  .avatar {
    height: 66px;
    margin-right: 15px;
  }
}

.mer-card {
  margin-bottom: 15px;
}

.tbs-font {
  width: 72px;
  height: 26px;
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  margin-left: 10px;
  //color: #3D3D3D;
}

.model-content {
  min-height: 1200px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;

  padding: 30px 0 0 0;

  .model-content-top {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .top-font-right {
      font-size: 14px;
      font-weight: 350;
      line-height: 26px;
      letter-spacing: 0em;
      color: #999999;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.msg-cell {
  padding: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0em;
  border-bottom: 1px solid #D8D8D8;
}

.login-btn {
  font-size: 13px;
  font-weight: normal;
  line-height: normal;
  color: #269FFA;
  cursor: pointer;
}

.welcome {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
}

.right-msg {
  position: absolute;
  right: 0;
  top: 0;
  height: 420px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  .msg-content {
    width: 280px;
    height: 420px;
    display: flex;
    flex-direction: column;

    .top-login {
      height: 68px;
      padding-left: 15px;
      padding-right: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      align-items: center;
      justify-content: flex-start;

      .avatar {
        margin-right: 15px;
      }
    }

    .msg-content-bottom {
      flex: 1;

      .message {
        padding: 10px 10px 0 10px;
        font-size: 14px;
        font-weight: bold;
        line-height: normal;
      }
    }
  }
}
</style>
