<template>
  <div class="container">
    <!-- 固定头部 -->
    <!--  <hover-search class="hover-search" :class="{show: topSearchShow}"></hover-search>-->
    <!-- 头部 包括登录等 -->
    <BaseHeader></BaseHeader>
    <!-- 搜索框、logo -->
    <Search @onSearch="search"></Search>
    <!-- 商品分类 -->
    <cateNav v-show="showCate" :showAlways="true" :large="false" :opacity="false"></cateNav>

    <!-- 路由 -->
    <router-view/>
    <!-- 底部栏 -->
    <BaseFooter></BaseFooter>
    <!-- 侧边栏 -->
    <fixedBar class="fixed-bar" :showTop="topShow" :class="{'show-fixed': topSearchShow}"></fixedBar>
  </div>
</template>

<script>
import HoverSearch from '@/components/header/hoverSearch'
import BaseHeader from '@/components/header/Header.vue'
import BaseFooter from '@/components/footer/Footer.vue'
import fixedBar from '@/components/fixed/index.vue'

export default {
  name: 'Index',
  components: {
    HoverSearch,
    BaseHeader,
    BaseFooter,
    fixedBar,
  },
  mounted() {
    let that = this
    window.onscroll = function () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      if (top > 300) {
        that.topShow = true
      } else {
        that.topShow = false
      }
    }
  },
  data() {
    return {
      autoCoupList: [],
      showCpmodel: false,
      // 顶部广告
      showNav: true, // 是否展示分类栏
      topSearchShow: true, // 滚动后顶部搜索栏展示
      carouselLarge: false, // 不同轮播分类尺寸
      carouselOpacity: false, // 不同轮播分类样式.
      topShow: false,
      showCate: true,
      switchArr: ['MerHome', 'MerInfo'],
    }
  },
  props: {
    pageData: {
      type: null,
      default: ''
    }
  },
  watch: {
    '$route.name': {
      handler(newVal, oldVal) {
        this.$nextTick(() => {
          let obj = this.switchArr.indexOf(newVal)
          if (obj > -1) {
            this.showCate = false
          } else {
            this.showCate = true
          }
        })
      }
    }
  },
  created() {
    let newVal = this.$route.name
    this.$nextTick(() => {
      let obj = this.switchArr.indexOf(newVal)
      if (obj > -1) {
        this.showCate = false
      } else {
        this.showCate = true
      }
    })
  },
  methods: {
    search(key) {
      this.$router.push({
        path: '/GoodsList',
        query: {
          keyword: key.keyword,
          selectFlag: key.selectFlag,
        },
      })
    },
    useScope(type, storeName) {
    },
  },
}
</script>

<style scoped lang="scss">
.hover-search {
  width: 100%;
  height: 60px;
  transform: translateY(-200px);
  position: fixed;
  top: 0;
  z-index: 9999;
  //box-shadow: 0 0 10px 2px rgb(90 90 90 / 60%);
  transition: 0.35s;
}

.show {
  transform: translateY(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  top: 0;
}

.fixed-bar {
  opacity: 0 !important;
  transform: translateY(-10px);
  transition: .35s;
  z-index: 1001;
  height: 0px !important;
  overflow: hidden;
}

.show-fixed {
  height: 374px !important;
  opacity: 1 !important;
  transform: translateY(0);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}


/* 2K */
@media screen and (min-width: 2561px) and (max-width: 3840px) {
  /* 样式 */
  .fixed-bar {
    position: fixed;
    right: 900px;
    top: 500px;
  }
}

/* 1080p */
@media screen and (max-width: 2560px) {
  /* 样式 */
  .fixed-bar {
    position: fixed;
    right: 0;
    top: 500px;
  }
}

@media screen and (max-width: 2025px) {
  /* 样式 */
  .fixed-bar {
    position: fixed;
    right: 0;
    top: 650px;
  }
}

</style>
