<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :width="400"
             centered
             @cancel="cancel">
      <div v-if="showFlag === 0" class="title-info cu-center text-bold">{{ title }}</div>
      <div v-if="showFlag === 1" class="title-info cu-center text-bold">Google 验证码</div>
      <div class="margin-top-xs">
        <code-input
            @change="completedInput"
            @complete="onComplete"
            :fields="6"
            :fieldWidth="46"
            :fieldHeight="46"
            :required="true"
        />
      </div>
      <div v-if="showFlag === 0" class="forget-link cursor-pointer text-grey" @click="toForget">
        {{ $t('pwd.pwd_001') }}?
      </div>
      <template #footer>
        <div class="cu-center">
          <div v-if="showFlag === 0" class="pay-btn cursor-pointer cu-center" @click="onFinish">{{ btnTitle }}</div>
          <div v-if="showFlag === 1" class="pay-btn cursor-pointer cu-center" @click="onGoogleFinish"> 验证
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '请输入6位数字支付密码'
    },
    visible: {
      type: Boolean,
      default: false
    },
    showFlag: {
      type: Number,
      default: 0
    },
    btnTitle: {
      type: String,
      default: '立即支付'
    },
  },
  data() {
    return {
      captcha: '',
    }
  },
  mounted() {
  },
  methods: {
    completedInput(e) {
      this.captcha = e
    },
    onComplete(e) {
      if (e) {
        this.onFinish(this.captcha)
      }
    },
    cancel() {
      this.captcha = ''
      this.$emit('complete', '')
    },
    onGoogleFinish(e) {
      if (!this.captcha || this.captcha.length < 6) {
        return this.$Message.error('请正确输入验证码')
      }
      this.$emit('googleFinish', e)
      this.captcha = ''
    },
    onFinish(e) {
      if (!this.captcha || this.captcha.length < 6) {
        return this.$Message.error('请正确输入验证码')
      }
      this.$emit('complete', e)
      this.captcha = ''
    },
    toForget() {
      this.$router.push({
        path: '/AccountSafe',
      })
      this.captcha = ''
    },
  }
}
</script>

<style scoped lang="scss">
.forget-link {
  display: flex;
  justify-content: flex-end;
  padding-right: 16px;
}

.title-info {
  font-size: 18px;
  font-weight: 350;
  line-height: 34px;
  letter-spacing: -0.17px;
  color: #3D3D3D;
}

.pay-btn {
  width: 365px;
  height: 50px;
  border-radius: 6px;
  background: #269FFA;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #FFFFFF;
}
</style>
