<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 40px'}">
      <template #title>
        我的收货地址
      </template>
      <div>
        <a-button class="btn-add" @click="showModal">+ 新增收货地址</a-button>
      </div>
      <div class="mt-3">
        <empty v-if="addressList.length === 0"/>
        <a-card v-else class="mb-3" :bordered="true" v-for="(item, index) in  addressList" :key="index">
          <template #title>
            收货人: {{ item.realName }}
          </template>
          <template #extra>
            <div class="flex">
              <div v-if="item.isDefault" class="text-red">默认地址</div>
              <div v-else class="cursor-pointer" @click="radioChange(item)">设置为默认地址</div>
            </div>
          </template>
          <div class="text-gray">
            <a-row :gutter="[16,24]">
              <a-col :span="3">手机:</a-col>
              <a-col :span="21"> + {{ item.countryCode + '  ' + item.phone }}</a-col>
            </a-row>
            <a-row :gutter="[16,24]">
              <a-col :span="3">地址:</a-col>
              <a-col :span="21">{{ item.province + item.city }}</a-col>
            </a-row>
            <a-row :gutter="[16,24]">
              <a-col :span="3">详细地址:</a-col>
              <a-col :span="16">{{ item.detail }}</a-col>
              <a-col :span="2" class="cu-center btn-cell">
                <div class="text-gray cursor-pointer" @click="delAddress(item)">删除</div>
              </a-col>
              <a-col :span="2" class="btn-cell1 ml-6">
                <div class="text-blue cursor-pointer" @click="viewModal(item)">修改</div>
              </a-col>
            </a-row>
          </div>
        </a-card>
      </div>
    </a-card>

    <a-modal v-model:open="xYVisible" title="添加地址" :width="820" :centered="true" :zIndex="10">
      <div class="padding">
        <a-form :labelCol="{ style: { width: '120px' } }"
                :model="userAddress"
                @finish="onFinish">
          <a-form-item label="姓名:" name="realName"
                       :rules="[{ required: true, message: '请输入姓名' }]">
            <a-input class="input-cell" v-model:value="userAddress.realName"
                     placeholder="请输入姓名" :maxlength="20"></a-input>
          </a-form-item>

          <a-form-item label="联系电话:" name="phone"
                       :rules="[{ required: true, message: $t('signUp.s_00038') }]">
            <a-input class="input-cell" :placeholder="$t('pwd.pwd_017')" v-model:value="userAddress.phone"
                     @input="$InputEvent.OnlyNumericLetters($event)"
                     v-in-number
                     @change="onKeyInputPhone">
              <template #prefix>
                <Vue3CountryIntl
                    schema="popover"
                    :selectedText="$t('pwd.pwd_018')"
                    :useChinese="true"
                    :searchInputPlaceholder="$t('common.com_002')"
                    v-model="userAddress.countryCode"
                    v-model:visible="countryView"
                    @change="changeCountry">
                  <Vue3CountryFlag :value="userAddress.iso2" @click="countryView = !countryView">
                    <template v-slot="{country}">
                      <span style="margin-left: 3px;">{{ '+' + country.dialCode }}</span>
                      <Icon type="md-arrow-dropdown"/>
                    </template>
                  </Vue3CountryFlag>
                </Vue3CountryIntl>
                <a-divider type="vertical" style="margin-left: -6px;"/>
              </template>
            </a-input>
          </a-form-item>

          <a-form-item label="所在地区:" name="address"
                       :rules="[{ required: true, message: '请选择所在地区' }]">
            <a-cascader v-model:value="userAddress.address"
                        :options="addressLists"
                        :load-data="loadAddress2"
                        :show-search="true"
                        :field-names="{ label: 'regionName', value: 'regionId'}"
                        change-on-select
                        size="large"
                        @change="changeCascader"
                        placeholder="请选择所在地区"/>
          </a-form-item>
          <a-form-item label="详细地址:" name="detail"
                       :rules="[{ required: true, message: '请输入详细地址' }]">
            <a-textarea :rows="4" v-model:value="userAddress.detail" placeholder="请输入详细地址"
                        :max-length="20"></a-textarea>
          </a-form-item>

          <a-form-item :labelCol="{ style: { width: '120px' } }">
            <a-checkbox style="margin-left: 120px" v-model:checked="userAddress.isDefault">设置为默认地址</a-checkbox>
          </a-form-item>
          <a-form-item :labelCol="{ style: { width: '120px' } }">
            <a-button style="margin-left: 120px;width: 126px;" size="large" type="primary" html-type="submit">确认
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <template #footer>
      </template>
    </a-modal>
  </div>
</template>

<script>
import {
  addAddress,
  checkPhone,
  delAddress,
  editAddress,
  getAddressDetail,
  getAddressList,
  setAddressDefault
} from '@/api/user.js'
import { getCountryCity } from '@/api/api.js'

import { Modal } from 'ant-design-vue'

export default {
  name: 'Profile',
  data() {
    return {
      addressList: [],
      loading: false,
      xYVisible: false,
      page: 1,
      limit: 20,
      countryView: false,
      addressSecondLists: [],
      addressId: 0, //地址id
      userAddress: {
        isDefault: false,
        realName: '',
        phone: '',
        address: '',
        iso2: 'us',
        countryCode: '1',
        detail: '',
        province: '',
        provinceId: '',
        city: '',
        cityId: '',
        street: '',
      }, //地址详情
      addressLists: [],
      phoneInvid: false

    }
  },
  mounted() {
    this.getAddressList()
    this.getUserAddress()
    this.loadAddress(0, 1)
  },
  methods: {
    onFinish() {
      let that = this
      // if (!this.phoneInvid) {
      //   return this.$Message.error('手机号填写不正确')
      // }
      if (that.addressId) {
        that.userAddress.id = that.addressId
        editAddress(this.userAddress)
            .then(res => {
              if (res.code === 200) {
                this.xYVisible = false
                this.getAddressList()
              } else {
                this.$Message.error(res.message)
              }
            })
      } else {
        addAddress(this.userAddress)
            .then(res => {
              if (res.code === 200) {
                this.xYVisible = false
                this.getAddressList()
              } else {
                this.$Message.error(res.message)
              }
            })
      }
    },
    viewModal(item) {
      this.addressId = item.id
      this.userAddress.realName = item.realName
      this.userAddress.isDefault = item.isDefault
      this.userAddress.detail = item.detail
      this.userAddress.countryCode = item.countryCode
      this.userAddress.phone = item.phone

      this.userAddress.province = item.province
      this.userAddress.provinceId = item.provinceId
      this.userAddress.city = item.city
      this.userAddress.cityId = item.cityId
      this.userAddress.address = [item.provinceId, item.city]
      this.xYVisible = true
    },
    showModal() {
      this.addressId = ''
      this.userAddress.realName = ''
      this.userAddress.isDefault = false
      this.userAddress.detail = ''
      this.userAddress.countryCode = 86
      this.userAddress.phone = ''
      this.userAddress.address = ''
      this.xYVisible = true
    },
    changeCascader(value, selectedOptions) {
      if (selectedOptions.length > 1) {
        this.userAddress.provinceId = selectedOptions[0].regionId
        this.userAddress.province = selectedOptions[0].regionName
        this.userAddress.cityId = selectedOptions[1].regionId
        this.userAddress.city = selectedOptions[1].regionName
      }
    },
    // 手机键盘输入实时反馈
    onKeyInputPhone: function (event) {
      let that = this
      if (!event.target._value) {
        return
      }
      // 检测手机是否已注册
      let data = {
        phone: event.target.value,
        countryCode: this.userAddress.countryCode
      }
      checkPhone(data)
          .then(res => {
            if (res.code === 200) {
              this.phoneInvid = true
            } else {
              this.phoneInvid = false
            }
          })
    },
    loadAddress(parentId, regionType) {
      let data = {
        parentId: parentId,
        regionType: regionType
      }
      getCountryCity(data)
          .then(res => {
            this.addressLists = res.data
            this.addressLists.forEach(item => {
              item.isLeaf = false
            })
          })
    },
    loadAddress2(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      let data = {
        parentId: selectedOptions[0].regionId,
        regionType: 2
      }
      getCountryCity(data)
          .then(res => {
            this.addressSecondLists = res.data
            targetOption.children = this.addressSecondLists
            targetOption.loading = false
          })
    },
    getUserAddress() {
      if (!this.addressId) return false
      let that = this
      getAddressDetail(this.addressId)
          .then(res => {
            if (res.data) {
              that.userAddress = res.data
              that.countryCode = res.data.countryCode
              that.addressInfo = [{
                regionName: res.data.province,
                parentId: 0,
                isChild: true,
                regionId: res.data.provinceId,
                regionType: 1
              }, {
                regionName: res.data.city,
                parentId: res.data.provinceId,
                regionId: res.data.cityId,
                isChild: false,
                regionType: 2
              }]
            }
          })
    },
    getAddressList() {
      let that = this
      that.loading = true
      getAddressList()
          .then(res => {
            that.addressList = res.data
            that.loading = false
          })
    },
    cancel() {
      this.xYVisible = false
    },
    changeCountry(event) {
      this.userAddress.iso2 = event.iso2
      this.userAddress.countryCode = event.dialCode
    },
    radioChange: function (item) {
      setAddressDefault(item.id)
          .then(res => {
            if (res.code === 200) {
              this.getAddressList()
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    delAddress: function (item) {
      let that = this
      Modal.warning({
        title: '确认删除吗?',
        okText: '确认',
        onOk: (okRes => {
          delAddress(item.id)
              .then(res => {
                that.getAddressList()
              })

          Modal.destroyAll()
        })
      })
    },
  }
}
</script>

<style scoped lang="scss">
.ivu-btn .ivu-btn-primary {
  color: #FFFFFF !important;
}

.btn-cell {
  width: 67px;
  height: 26px;
  border-radius: 50px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #E4E4E4;
}

.btn-cell1 {
  width: 57px;
  height: 26px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #269FFA;

  display: flex;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
}

.btn-add {
  border-color: #2b85e4 !important;
  color: #2b85e4 !important;
}

.input-cell {
  height: 40px;
  border: 1px solid #E5E5E5;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
