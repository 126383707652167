<template>
  <div>
    <div v-if="viewIndex === 1" class="page-container-all width_1200_auto">
      <a-image :preview="false" class="all-page-container" :src="require('@/assets/partner/back.png')"></a-image>
      <div class="page-join-content">
        <div class="font-top1">
          <a-image :preview="false" :width="430" :height="95" :src="require('@/assets/partner/topfront.png')"  />
        </div>
<!--        <div class="font-top2">-->
<!--          全球合伙人 赚取<span class="font-top3">高额佣金</span>-->
<!--        </div>-->
        <div class="page-join cursor-pointer" @click="showModeltop">
          <div class="font-cus">立即加入</div>
        </div>
        <div class="page-container">
          <a-card :bordered="false" :body-style="{padding: '20px', width:'1200px'}">
            <template #title>
              <div class="flex-title">
                <div class="line-shu"></div>
                <div class="ml-2">关于全球合伙人计划介绍</div>
              </div>
            </template>
            <div class="font-info">
              欢迎加入51担保全球合伙人计划，这是一个专为影响力人士设计的高收益计划，旨在帮助您通过您的社交影响力实现最大化的收益。无论您是在社交媒体、博客、视平台，还是其他渠道拥有广泛的人脉资源、我们的担保全球合伙人计划都能为您提供专属的丰厚的佣金回报。
            </div>
          </a-card>
          <a-card style="margin-top: 30px" :bordered="false"
                  :body-style="{padding: '20px', width:'1200px', borderRadius :'0'}">
            <template #title>
              <div class="flex-title">
                <div class="line-shu"></div>
                <div class="ml-2">合伙人收益介绍</div>
              </div>
            </template>
            <div class="font-info">
              一旦您成功加入51担保全球合伙人计划，您将享有推广返佣的额外合伙人专属返佣。
            </div>
            <div class="mt-2">
              <a-row>
                <a-col :span="8" class="top-bg1">被推荐商家层级</a-col>
                <a-col :span="16" class="top-bg2">推荐人收益</a-col>
              </a-row>
              <a-row>
                <a-col :span="8" class="content-info-1">无限层级商家</a-col>
                <a-col :span="16" class="content-info-2">
                  <div>商家入驻返佣<span class="text-red">30USDT</span></div>
                  <div>手续费返佣<span class="text-blue">20%</span></div>
                </a-col>
              </a-row>
            </div>
          </a-card>

          <div class="page-container-info">
            <a-card :bordered="false" :body-style="{padding: '20px', width:'100%'}">
              <template #title>
                <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="32" :height="32"></a-image>
                常见问题
              </template>
              <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
                <a-collapse-panel :header="item.issue" v-for="(item, index) in issueList" :key="index">
                  <div class="html-font" v-html="item.answer"></div>
                </a-collapse-panel>
              </a-collapse>
            </a-card>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="container width_1200_auto">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/">
            首页
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          合伙人
        </a-breadcrumb-item>
      </a-breadcrumb>
      <div class="margin-top-sm">
        <div>
          <a-row :gutter="[16, 24]">
            <a-col :span="12">
              <a-card :bordered="false" :body-style="{height: '144px', width:'630px', padding: '0'}">
                <div class="card-top">
                  <div class="item-1">
                    <a-image :src="require('@/assets/share/inOne.png')" :width="100" :height="100"
                             :preview="false"></a-image>
                  </div>
                  <div class="item-2">
                    <div class="item-title">邀请人数</div>
                    <div class="item-number">
                      {{ pInfo.spreadCount ? pInfo.spreadCount : '0' }}
                    </div>
                    <div class="item-unit">(人)</div>
                  </div>
                </div>
              </a-card>
            </a-col>
            <a-col :span="12">
              <a-card :bordered="false" :body-style="{height: '144px', width:'630px', padding: '0'}">
                <div class="card-top">
                  <div class="item-1">
                    <a-image :src="require('@/assets/share/inTwo.png')" :width="100" :height="100"
                             :preview="false"></a-image>
                  </div>
                  <div class="item-2">
                    <div class="item-title">合伙人佣金</div>
                    <div class="item-number">
                      {{ pInfo.brokerage ? pInfo.brokerage : '0.00' }}
                    </div>
                    <div class="item-unit">(USDT)</div>
                  </div>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
      </div>

      <div class="margin-top-sm">
        <div style="padding: 10px 20px 0 20px">
          <a-tabs v-model:activeKey="TabCur" @change="changeGxActive">
            <a-tab-pane key="1">
              <template #tab>
                <span class="tbs-font">邀请详情</span>
              </template>
              <a-table :pagination="false" :loading="loading" :columns="columns" :dataSource="pList">
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'nickname'">
                    <div class="text-bold">{{ record.nickname }}</div>
                  </template>
                  <template v-if="column.key === 'uid'">
                    <div class="text-gray">{{ record.uid }}</div>
                  </template>
                  <template v-if="column.key === 'joinTime'">
                    <div class="text-gray">{{ record.joinTime }}</div>
                  </template>
                  <template v-if="column.key === 'joinBrokerageSum'">
                    <div class="text-red text-bold">{{ record.joinBrokerageSum ? record.joinBrokerageSum : '0.00' }}
                      USDT
                    </div>
                  </template>
                  <template v-if="column.key === 'orderBrokerageSum'">
                    <div class="text-red text-bold">{{ record.orderBrokerageSum ? record.orderBrokerageSum : '0.00' }}
                      USDT
                    </div>
                  </template>
                </template>
              </a-table>
            </a-tab-pane>
            <a-tab-pane key="2">
              <template #tab>
                <span class="tbs-font">收益详情</span>
              </template>
              <a-table :pagination="false" :loading="loading" :columns="columns2" :dataSource="pList">
                <template #headerCell="{ column }">
                  <template v-if="column.key === 'time'">
                    <div class="text-right">{{ column.title }}</div>
                  </template>
                </template>
                <template #bodyCell="{ column, record }">
                  <template v-if="column.key === 'amount'">
                    <div class="text-red text-bold">{{ record.amount ? record.amount : '0.00' }} USDT</div>
                  </template>
                  <template v-if="column.key === 'nickname'">
                    <div class="text-bold">{{ record.nickname }}</div>
                  </template>
                  <template v-if="column.key === 'uid'">
                    <div class="text-gray">{{ record.uid }}</div>
                  </template>
                  <template v-if="column.key === 'type'">
                    <div class="text-bold">{{ record.type }}</div>
                  </template>
                  <template v-if="column.key === 'time'">
                    <div class="text-right">{{ record.time }}</div>
                  </template>
                </template>
              </a-table>
            </a-tab-pane>
          </a-tabs>
        </div>
        <!-- 分页 -->
        <div class="page-size mt-3" v-if="pList && pList.length > 0">
          <a-pagination :page-size="limit" :total="total"
                        :show-size-changer="false"
                        show-less-items @change="changePageNum" @page-size-change="changePageSize"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  userCenterInfo,
} from '@/api/user.js'

import {
  getMerBrokerageApi,
  getMerInviteCountApi,
} from '@/api/merchant.js'

import {
  partnerInfo,
  partnerList,
  isApplyPartner,
} from '@/api/tg.js'

export default {
  data() {
    return {
      activeKey: null,
      issueList: [{
        issue: '如何成为51担保全球合伙人?',
        answer: '直接推荐 5 名商家入驻，自动晋升为51担保全球合伙人。'
      },
        {
          issue: '哪些人可能够加入51担保全球合伙人计划?',
          answer: '51担保全球合伙人计划欢迎任何人加入我们!'
        }],
      tips: '',
      showModel: false,
      viewIndex: 1,
      TabCur: '1',
      listCount: 5,
      userInfo: '',
      pInfo: {},
      pList: [],
      page: 1,
      limit: 10,
      loadTitle: this.$t('common00004'),
      InviteCount: 0,
      Brokerage: 0,
      total: 0,
      loading: false,
      columns2: [
        {
          title: '用户',
          dataIndex: 'nickname',
          key: 'nickname'
        }, {
          title: 'ID',
          dataIndex: 'uid',
          key: 'uid'
        }, {
          title: '返佣类型',
          dataIndex: 'type',
          key: 'type'
        }, {
          title: '返佣金额',
          dataIndex: 'amount',
          key: 'amount'
        }, {
          title: '时间',
          dataIndex: 'time',
          key: 'time'
        },
      ],
      columns: [{
        title: '用户',
        dataIndex: 'nickname',
        key: 'nickname'
      }, {
        title: 'ID',
        dataIndex: 'inviteCode',
        key: 'inviteCode'
      }, {
        title: '加入时间',
        dataIndex: 'joinTime',
        key: 'joinTime'
      }, {
        title: '入驻费返佣',
        dataIndex: 'joinBrokerageSum',
        key: 'joinBrokerageSum'
      }, {
        title: '返佣金额',
        dataIndex: 'orderBrokerageSum',
        key: 'orderBrokerageSum'
      },]
    }
  },
  created() {
    if (this.$store.getters.token) {
      let _this = this
      _this.getUserCenterInfo()
      _this.getMerBrokerage()
      _this.getMerInviteCount()
      _this.upCallback()
    } else {
      this.$router.push({
        path: '/Login',
        query: {
        }
      })
    }
  },
  methods: {
    changePageNum(val) { // 修改页码
      this.page = val
      this.upCallback()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.upCallback()
    },
    changeGxActive(e) {
      this.page = 1
      this.upCallback()
    },
    showModeltop() {
      isApplyPartner()
          .then((res) => {
            if (res.code === 200) {
              if (res.data) {
                this.$router.push({
                  path: '/PartnerJoin',
                  query: {
                    apply: 1,
                  }
                })
              } else {
                this.$router.push({
                  path: '/PartnerJoin',
                  query: {
                  }
                })
              }
            } else {
              this.$Message.error(res.message)
            }
          })
    },
    upCallback() {
      this.loading = true
      let data = {
        page: this.page,
        limit: this.limit
      }
      if (this.TabCur === '1') {
        partnerList(data)
            .then((res) => {
              this.pList = res.data
              if (this.pList && this.pList.length > 0) {
                this.total = res.data[0].total
              }
              this.loading = false
            })
      }
      if (this.TabCur === '2') {
        partnerInfo(data)
            .then((res) => {
              this.pList = res.data.child
              this.total = res.data.total
              this.loading = false
            })
      }
    },
    getPartnerInfoData() {
      let that = this
      let data = {
        page: that.page,
        limit: that.limit
      }
      partnerInfo(data)
          .then(res => {
            let data = res.data
            if (data) {
              that.pInfo.spreadCount = data.spreadCount
              that.pInfo.brokerage = data.brokerage
            }
          })
    },
    getMerBrokerage() {
      getMerBrokerageApi()
          .then(res => {
            let data = res.data
            if (data) {
              this.Brokerage = data
            }
          })
    },
    getMerInviteCount() {
      getMerInviteCountApi()
          .then(res => {
            let data = res.data
            if (data) {
              this.InviteCount = data
            }
          })
    },
    getUserCenterInfo: function () {
      let that = this
      userCenterInfo()
          .then(res => {
            let data = res.data
            that.userInfo = data
            if (that.userInfo.isPartner) {
              that.viewIndex = 2
              that.page = 1
              that.limit = 15
              that.getPartnerInfoData()
              that.upCallback()
            }
          })
    },
  }
}
</script>

<style scoped lang="scss">
.page-join-content {
  position: absolute;
  top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;

  .font-top1 {
    font-size: 58px;
    font-weight: normal;
    line-height: 25px;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

  .font-top2 {
    margin-top: 40px;
    font-size: 36px;
    font-weight: normal;
    line-height: 25px;
    letter-spacing: 0px;
    /* 全球合伙人 赚取 */
    color: #FFFFFF;

  }

  .font-top3 {
    margin-top: 40px;
    font-size: 36px;
    font-weight: normal;
    line-height: 25px;
    letter-spacing: 0px;
    /* 高额佣金 */
    color: #FFC12E;
  }

  .page-join {
    margin-top: 40px;
    width: 132px;
    height: 38px;
    border-radius: 50px;
    opacity: 1;
    background: #FFCC00;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.17px;
    color: #3D3D3D;
  }
}

.back-select {
  border: 1px solid #2b85e4 !important;
}

.table-top {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .search {
    width: 384px;
    height: 37px;
    border-radius: 50px;
    margin-right: 20px;
  }

  .menu-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 20px;

    .menu {
      cursor: pointer;
      width: 98px;
      height: 30px;
      border-radius: 50px;
      box-sizing: border-box;
      border: 1px solid #E8E8E8;
      font-size: 14px;
      font-weight: normal;
      line-height: 26px;

      display: flex;
      justify-content: center;
      align-items: center;

      margin-right: 20px;

      .pepole-count {
        color: #F6BC33;
      }
    }
  }
}

.card-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item-1 {
    width: 207px;
    height: 144px;

    display: flex;
    justify-content: center;
    align-items: center;
    background: #F8F9F8;
  }

  .item-2 {
    width: 100%;
    height: 144px;
    padding: 30px;

    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .item-title {
      font-size: 16px;
      font-weight: 900;
      line-height: 26px;
      letter-spacing: 0em;
    }

    .item-number {
      margin-top: 13px;
      font-size: 38px;
      font-weight: bold;
      line-height: 20px;
      color: #FF3F3F;
      margin-left: -5px;
    }

    .item-unit {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 0em;
      color: #C3C3C3;
    }
  }
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1200px;
}

.html-font {
  font-size: 13px;
  font-weight: normal;
  color: #999999;
}

.page-container-info {
  margin-top: 20px;
}

.content-info-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F0F0F0;
  height: 85px;
  min-height: 85px;
  border-right: 3px solid #FFFFFF;
  color: #333333;
}

.content-info-2 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  color: #333333;

  background: #F0F0F0;
  height: 85px;
  min-height: 85px;
}

.top-bg1 {
  background: #FFEBBC;
  height: 40px;
  min-height: 40px;
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: -0.17px;
  color: #6E6E6E;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  border-right: 3px solid #FFFFFF;
  padding-left: 3px;
}

.top-bg2 {
  padding-left: 3px;
  background: #FFEBBC;
  height: 40px;
  min-height: 40px;
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: -0.17px;
  color: #6E6E6E;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-title-view {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  background: rgba(68, 68, 68, 0.6);

  font-size: 12px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;

  display: flex;
  justify-content: center;
  align-items: center;
}

.content-cus {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;

  .info-font {
    font-size: 16px;
    font-weight: normal;
    line-height: 38px;
    height: 38px;
    letter-spacing: 0px;
    color: #999999;
  }

  .info-font-1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    height: 32px;
    letter-spacing: 0px;
    margin-top: 10px;
  }

  .botm-btns {
    margin-top: 10px;
    width: 120px;
    height: 35px;
    border-radius: 30px;
    box-sizing: border-box;
    border: 1px solid #E2E2E2;

    font-size: 14px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }
}

.flex-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  .line-shu {
    width: 3px;
    height: 17px;
    background: #269FFA;
    border-radius: 10px;
  }
}

.page-container {
  width: 1200px;
  margin-top: 300px;

  .font-info {
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0em;
    color: #7B7B7B;
  }
}


.page-container-all {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 3px;
  min-height: 1200px;
  background-color: #1098D1;

  .all-page-container {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 1200px;
  }
}
</style>
