<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px'}">
      <div class="d-flex cu-center">
        <div class="me-auto" style="width: 520px;">
          <a-tabs v-model:activeKey="activeKey" @change="changeActive">
            <a-tab-pane :key="0" :tab="mesCount1 > 0 ? '系统消息(' +mesCount1+ ')' :'系统消息' "></a-tab-pane>
            <a-tab-pane :key="1" :tab="mesCount2 > 0 ? '订单消息(' +mesCount2+ ')' :'订单消息' "></a-tab-pane>
            <a-tab-pane :key="2" :tab="mesCount3 > 0 ? '客服消息(' +mesCount3+ ')' :'互动消息' "></a-tab-pane>
          </a-tabs>
        </div>
        <div class="cursor-pointer msg-btn" @click="selectedClick">
          全部已读 ({{ mesCount }})
        </div>
      </div>

      <div v-if="activeKey === 0 || activeKey === 1">
        <a-list>
          <a-list-item class="cursor-pointer" v-for="(item, index) in msgList" :key="index" @click="setReadOnce(item)">
            <a-list-item-meta>
              <template #avatar>
                <div class="cu-avatar round lg" v-if="item.type === 0"
                     style="background: linear-gradient(90deg, #F77774 0%, #FEAC72 97%);">
                  <Icon type="ios-notifications-outline"/>
                </div>
                <div class="cu-avatar round lg" v-if="item.type === 1"
                     style="background: linear-gradient(90deg, #37AAFA 0%, #6BD2FD 99%);">
                  <Icon type="ios-paper-outline"/>
                </div>
                <div class="cu-avatar round lg" v-if="item.type === 2"
                     style="background: linear-gradient(90deg, #FB6C25 0%, #FD9335 99%);">
                  <Icon type="ios-mail-outline"/>
                </div>
                <div class="cu-avatar round lg" v-if="item.type === 3"
                     style="background: linear-gradient(90deg, #DBBA83 0%, #B38835 99%);">
                  <Icon type="ios-notifications-outline"/>
                </div>
              </template>
              <template #title>
                <div>{{ item.title }}</div>
                <div class="text-sm text-gray">{{ item.updateTime }}</div>
              </template>
              <template #description>
                <div v-html="item.message"></div>
              </template>
            </a-list-item-meta>
            <template #extra>
              <span v-if="item.readFlag === 0" class="text-gray">未读</span>
            </template>
          </a-list-item>
        </a-list>
      </div>

      <div v-if="activeKey === 2">
        <a-list>
          <a-list-item class="cursor-pointer" v-for="(item, index) in messages" :key="index" @click="showIM(item)">
            <a-list-item-meta>
              <template #avatar>
                <div class="cu-avatar round lg">
                  <a-badge :count="item.mesCount >0 ? item.mesCount : ''">
                    <a-avatar :src="item.headImg" :size="48"/>
                  </a-badge>
                </div>
              </template>
              <template #title>
                <div>{{ item.merName }}</div>
              </template>
              <template #description>
                <div v-html="item.topTitle"></div>
              </template>
            </a-list-item-meta>
            <template #extra>
              <div class="text-sm text-gray">{{ item.updateFormatTime }}</div>
            </template>
          </a-list-item>
        </a-list>
      </div>
    </a-card>
    <!-- 分页 -->
    <div class="page-size margin-top-xs" v-if="msgList && msgList.length > 0">
      <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                    :show-size-changer="false"
                    @page-size-change="changePageSize"/>
    </div>
  </div>
</template>

<script>
import {
  getMsgs,
  getReadAll,
  getReadCount,
  getReadCountByType,
  getReadMsgs,
  getReadOnce,
  msgCusList,
} from '@/api/message.js'

export default {
  name: 'Message',
  data() {
    return {
      selected: 0,
      activeKey: 0,
      mesCount: '',
      readMsgs: [],
      mesCount1: '',
      mesCount2: '',
      mesCount3: '',
      mesCount4: '',
      page: 1,
      limit: 10,
      total: 0,
      msgList: [],
      messages: [],
    }
  },
  created() {
    this.getAllReadCount()
    this.getAll()
    this.getReadMsgsInfo()
    this.upCallback()
    this.queryMsgCusList()
  },
  watch: {
    '$store.getters.readCount': {
      handler(val) {
        if (this.$store.getters.token) {
          this.getAllReadCount()
          this.getAll()
          this.queryMsgCusList()
        }
      }
    }
  },
  methods: {
    showIM(item) {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: item.merId
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    changePageNum(val) { // 修改页码
      this.page = val
      this.upCallback()
    },
    changePageSize(val) { // 修改页数
      this.page = 1
      this.limit = val
      this.upCallback()
    },
    setReadOnce(item) {
      if (item.readFlag === 1) {
        return
      }
      let data = {
        id: item.id
      }
      getReadOnce(data)
          .then(res => {
            this.msgList = []
            this.page = 1
            this.getAllReadCount()
            this.upCallback()
          })
          .catch(err => {
          })
    },
    upCallback() {
      let pageNum = this.page // 页码, 默认从1开始
      let pageSize = this.limit // 页长, 默认每页10条
      let data = {
        id: this.activeKey,
        itemId: this.itemId,
        page: pageNum,
        limit: pageSize
      }
      getMsgs(data)
          .then((res) => {
            this.total = res.data.total
            this.msgList = res.data.list
          })
    },
    getAll() {
      this.getReadMsgsInfo()
      this.readCountByType(0)
      this.readCountByType(1)
      this.readCountByType(2)
      this.readCountByType(3)
    },
    queryMsgCusList() {
      msgCusList()
          .then(res => {
            this.messages = res.data
            let msgCountIm = 0
            if (this.messages && this.messages.length > 0) {
              this.messages.forEach(item => {
                msgCountIm += item.mesCount
              })
            }
            this.mesCount3 = msgCountIm
          })
    },
    readCountByType(typeId) {
      let para = {
        id: typeId
      }
      getReadCountByType(para)
          .then(res => {
            if (typeId === 0) {
              this.mesCount1 = res.data
            }
            if (typeId === 1) {
              this.mesCount2 = res.data
            }
            if (typeId === 3) {
              this.mesCount4 = res.data
            }
          })
    },
    getReadMsgsInfo() {
      getReadMsgs()
          .then(res => {
            this.readMsgs = res.data
          })
    },
    getAllReadCount() {
      getReadCount()
          .then(res => {
            this.mesCount = res.data
          })
    },
    changeActive(e) {
      this.activeKey = e
      this.msgList = []
      this.page = 1
      if (this.activeKey === 2) {
        this.queryMsgCusList()
      } else {
        this.upCallback()
      }
    },
    selectedClick(index) {
      this.selected = index
      getReadAll()
          .then(res => {
            this.mesCount = 0
            this.page = 1
            this.getAllReadCount()
            this.upCallback()
            this.getAll()
            this.queryMsgCusList()

            this.$store.commit('NO_READ_COUNT', this.mesCount)
          })
    }
  }
}
</script>

<style scoped lang="scss">
.msg-btn {
  font-size: 14px;
  font-weight: 350;
  line-height: 25px;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
