<template>
  <div class="navbar" :class="{'small-search-box':useClass == 'fixed-show'}">
    <div class="container width_1200_auto flex flex-a-c">
      <img
          src="../assets/index/logo.png"
          v-if="showLogo"
          class="logo-img"
          alt=""
          @click="toIndexClick"
      />
      <div :class="{'small-search-box':useClass == 'fixed-show'}" class="search-box">
        <a-input
            v-model:value="keyword"
            size="large"
            class="search"
            :placeholder="$t('srh_0001')">
          <template #prefix>
            <a-select v-model:value="selectMerOrPro" :bordered="false">
              <a-select-option value="0" class="search-prefix">{{ $t('srh_0002') }}</a-select-option>
              <a-select-option value="1" class="search-prefix">{{ $t('srh_0003') }}</a-select-option>
            </a-select>
            <a-divider type="vertical"/>
          </template>
          <template #suffix>
            <Button type="primary" shape="circle" icon="ios-search" @click="search"></Button>
          </template>
        </a-input>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'search',
  props: {
    where: {
      type: Object,
      default: {}
    },
    showTag: { // 是否展示搜索栏下方热门搜索
      type: Boolean,
      default: true
    },
    showLogo: { // 是否展示左侧logo
      type: Boolean,
      default: true
    },
    selectFlag: { // 是否为店铺页面
      type: String,
      default: ''
    },
    storeId: { // 是否为店铺页面
      type: String,
      default: ''
    },
    hover: {
      type: Boolean,
      default: false
    },
    useClass: {
      type: null,
      default: ''
    }
  },
  watch: {
    '$route': {
      handler(val) {
        if (val.name !== 'GoodsList') {
          return
        }
        this.$nextTick(() => {
          this.keyword = this.$route.query.keyword
          const selectFlag = this.$route.query.selectFlag
          if (selectFlag === '0' || selectFlag === '1') {
            this.selectMerOrPro = selectFlag + ''
          } else {
            this.selectMerOrPro = '0'
          }
          if (this.keyword && (selectFlag === '0' || selectFlag === '1')) {
            this.search()
          }
        })
      }
    }
  },
  data() {
    return {
      selectMerOrPro: '0',
      keyword: '', // 搜索内容
      onlyStore: false,
      promotionTags: [],
    }
  },
  created() {
    if (this.where) {
      this.keyword = this.where.keyword
    }
    if (this.selectFlag) {
      this.selectMerOrPro = this.selectFlag
    }
  },
  methods: {
    toIndexClick() {
      this.$router.push({
        path: '/',
      })
    },
    search() { // 全平台搜索商品
      const data1 = {
        keyword: this.keyword,
        selectFlag: this.selectMerOrPro
      }
      this.$emit('onSearch', data1)
      this.$emit('onSearchList', data1)
    },
  },
  computed: {},
}
</script>
<style scoped lang="scss">
:deep(button) {
  color: #FFFFFF !important;
}

.only-store {
  text-align: right;
  color: #F31947;
  cursor: pointer;
}

.navbar {
  height: 113px;
}

.search-icon {
  width: 100%;
  height: 100%;
}

.small-search-box {
  height: 60px;
  margin: 0 !important;
}

.container {
  position: relative;
  height: 100%;
}

.search {
  width: 920px;
  height: 42px;
  margin: 10px 0px 5px 0;

  border-radius: 50px;


  :deep(.ivu-input.ivu-input-large) {
    border: 1.4px solid #8DC9F6;
    box-sizing: border-box;
    border-radius: 19.6px;
    position: relative;
    padding-left: 26px;
    font-size: 14px;
    font-weight: normal;
    height: 37.8px;
    color: #999;

    &:focus {
      box-shadow: none;
    }
  }

  :deep(.ivu-input-group-append) {
    border-radius: 19.6px !important;
    cursor: pointer;
    box-sizing: border-box;
    border: 1.4px solid #F31947;
    width: 67.2px;
    height: 37.8px;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    z-index: 99;
    background-color: #F31947;
    color: #ffffff;

    button {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }
  }
}

.search-box {
  margin-left: 28px;
}

.logo-img {
  max-width: 150px;
  cursor: pointer;
}

.store-search {
  width: 55.6px;
  padding: 0 9px;
  border-radius: 0;
  border-radius: 3px;

  &:nth-child(2) {
    width: 55px;
    margin-left: -2px;
    border-radius: 3px;
  }
}

.btn-div {
  position: relative;
  height: 0px;
  top: -38px;
  left: 352px;
}

.history-list {

  margin-left: 28px;
}

.history-item {
  font-size: 13px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0px;
  margin-right: 17px;
  color: #666666;
  cursor: pointer;
}
</style>
