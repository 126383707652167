import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import router from './router'
import store from './store'
import VueQr from 'vue-qr/src/packages/vue-qr.vue'
import Antd from 'ant-design-vue'
import i18n from '@/i18n/index'
import Vue3CountryIntl from 'vue3-country-intl'
import Cookie from 'js-cookie'
import Vue3CountryFlag from 'vue3-country-intl/lib/vue3CountryFlag.esm.min.js'
import vue3GoogleLogin from 'vue3-google-login'
import vue3videoPlay from 'vue3-video-play'

// 引入css
import 'vue3-country-intl/lib/vue3-country-intl.css'
import 'vue3-country-intl/lib/vue3-country-flag.css'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import '@/assets/styles/global.scss'
import '@/assets/styles/vuetify.css'
import 'ant-design-vue/dist/reset.css'
import 'vue3-video-play/dist/style.css' // 引入css
import '@/assets/styles/colorui/main.css'
import '@/assets/styles/colorui/animation.css'
import '@/assets/iconfont/iconfont.css'

// 全局引入封装组件
import { InstallAll } from '@/components/global.js'

import * as InputEvent from '@/utils/inputEvent'

import ArcoVue from '@arco-design/web-vue'
import ArcoVueIcon from '@arco-design/web-vue/es/icon'
import '@arco-design/web-vue/dist/arco.css'

import codeInput from '@/components/codeInput/codeInput.vue'

import Vue3Lottie from 'vue3-lottie'
// import 'vue3-lottie/dist/style.css'

let flagFilePath = require.context('vue3-country-intl/lib/country-flag-svgs', true, /\.svg$/)

const app = createApp(App)

app.config.globalProperties.$Cookie = Cookie
app.config.globalProperties.$InputEvent = InputEvent

app.component(Vue3CountryIntl.name, Vue3CountryIntl)
app.component('codeInput', codeInput)

// 全局INPUT不能输入汉字
app.directive('no-chinese', {
  mounted(el) {
    el.addEventListener('input', (event) => {
      const value = event.target.value;
      const reg = /[\u4e00-\u9fa5]/g; // 匹配汉字的正则表达式
      if (reg.test(value)) {
        // 如果输入包含汉字，则移除它们
        event.target.value = value.replace(reg, '');
      }
    });
  }
});

//
app.directive('in-number', {
  mounted(el) {
    el.addEventListener('input', (event) => {
      const value = event.target.value;
      const reg =  /[^\d]/g; // 匹配汉字的正则表达式
      if (reg.test(value)) {
        // 如果输入包含汉字，则移除它们
        event.target.value = value.replace(reg, '');
      }
    });
  }
});

app.use(vue3videoPlay)
  .use(Vue3Lottie)
  .use(ArcoVueIcon)
  .use(Antd)
  .use(i18n)
  .use(VueQr)
  .use(store)
  .use(router)
  .use(ViewUIPlus)
  .use(InstallAll)
  .use(ArcoVue, {
    componentPrefix: 'arco'
  })
  .use(Vue3CountryFlag, flagFilePath)
  .use(InstallAll)
  .use(vue3GoogleLogin, {
    clientId: '533912883501-k07iqpskas1lbu9rbrgiig004p21gkdn.apps.googleusercontent.com',
    buttonConfig: {
      type: 'icon',
      shape: 'circle',
      size: 'medium',
      width: '32',
      logo_alignment: 'center',
      text: ''
    }
  })
  .mount('#app')
