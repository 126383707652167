<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '20px 30px'}" :loading="loading">
      <template #title>
        <div class="d-flex">
          <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="21" :height="21"></a-image>
          <div class="text-bold">常见问题</div>
        </div>
      </template>
      <empty v-if="!issueList || issueList.length === 0"/>
      <div class="mt-1">
        <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
          <a-collapse-panel :header="item.issue" v-for="(item, index) in issueList" :key="index">
            <div class="html-font" v-html="item.answer"></div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </a-card>
  </div>
</template>

<script>

import { queryNormalIssue } from '@/api/tg'

export default {
  name: 'Help',
  data() {
    return {
      activeKey: '',
      loading: false,
      issueList: []
    }
  },
  created() {
    this.getIssueList()
  },
  methods: {
    getIssueList(type) {
      let data = {
        page: 1,
        limit: 100
      }
      queryNormalIssue(data)
          .then(res => {
            this.issueList = res.data.list
          })
    },
  }
}
</script>

<style scoped lang="scss">
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 20px 0;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
