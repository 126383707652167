<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="visible"
             :maskClosable="false"
             :afterClose="cancel"
             :destroyOnClose="true"
             :width="380"
             @cancel="cancel">
      <template #title>
        <div class="cu-center">安全验证</div>
      </template>
      <div class="padding-top-xs">
        <div class="title-info text-bold">{{ title }}</div>

        <div class="padding-top-xs">
          <code-input
              @change="completedInput"
              @complete="onComplete"
              :fields="6"
              :fieldWidth="46"
              :fieldHeight="46"
              :required="true"
          />
        </div>
        <div v-if="modalIndex !== 3" class="forget-link cursor-pointer"
             :class="disabled ? 'text-gray' : 'text-blue'"
             @click="codeSend">
          {{ text }}
        </div>
      </div>
      <template #footer>
        <div class="cu-center">
          <div class="pay-btn cursor-pointer cu-center" @click="onFinish">{{ btnTitle }}</div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import sendVerifyCode from '@/utils/SendVerifyCode'
import { sendCodeByPaypwd } from '@/api/user'

export default {
  props: {
    title: {
      type: String,
      default: '请输入6位数字验证码'
    },
    modalIndex: {
      type: Number,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    btnTitle: {
      type: String,
      default: '确认'
    },
  },
  mixins: [sendVerifyCode],
  watch: {
    modalIndex: {
      handler(val) {
        if (val) {
          if (this.timeTask) {
            clearTimeout(this.timeTask)
            this.timeTask = null
            this.disabled = false
            this.text = '获取验证码'
          }
        }
      },
    },
    visible: {}
  },
  data() {
    return {
      captcha: '',
      sendSmsCode: false,
      timeTask: null,
    }
  },
  methods: {
    completedInput(e) {
      this.captcha = e
    },
    onComplete(e) {
      if (e) {
        this.onFinish(this.captcha)
      }
    },
    cancel() {
      this.captcha = ''
      this.sendSmsCode = false
      this.$emit('complete', '')
    },
    onFinish(e) {
      if (!this.captcha || this.captcha.length < 6) {
        return this.$Message.error('输入验证码不正确或者已过期')
      }
      this.sendSmsCode = false
      this.$emit('complete', e)
      this.captcha = ''
    },
    codeSend() {
      let that = this
      if (that.disabled) return
      let data = {
        sendCodeFlag: this.modalIndex
      }
      sendCodeByPaypwd(data)
          .then(res => {
            this.sendSmsCode = true
            this.timeTask = that.sendCode()
          })
    },
  }
}
</script>

<style scoped lang="scss">
.forget-link {
  display: flex;
  justify-content: flex-end;
}

.title-info {
  font-size: 15px;
  font-weight: 350;
  line-height: 24px;
  letter-spacing: -0.17px;
  color: #3D3D3D;
  padding-right: 10px;
}

.pay-btn {
  width: 410px;
  height: 50px;
  border-radius: 6px;
  background: #269FFA;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #FFFFFF;
}
</style>
