<template>
  <div class="scroll-show">
    <div class="content clearfix">
      <cateNav class="cate" :hover="true" :showNavBar="false"
               useClass="fixed-show"></cateNav>
      <Search
          class="search-con"
          @onSearch="search"
          :hover="true"
          ref="search"
          :showLogo="false"
          :showTag="false"
          useClass="fixed-show"
      ></Search>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInfo: {}, // 用户信息
    }
  },
  computed: {},
  methods: {
    search(key) {
      this.$router.push({
        path: '/GoodsList',
        query: {
          keyword: key.keyword,
          selectFlag: key.selectFlag,
        },
      })
    },
  },
  mounted() {
  },
}
</script>
<style lang="scss" scoped>
.content {
  width: 1200px;
  height: 60px;
  margin: 0 auto;
  position: relative;

  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.cate {
  float: left;
  width: 180px !important;
}

.search-con {
  float: left;
  width: 920px;
  overflow: hidden;
  margin-top: -27px;
}

.cart {
  height: 60px;
}

.cart-icon {
  width: 30px;
  float: left;
  font-size: 25px;

  color: #F31947;
  z-index: 1;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

.cart-badge {
  position: absolute;
  font-style: normal;
  right: 165px;
  display: block;
  background-color: #F31947;
  color: #fff;
  font-size: 12px;
  width: 17px;
  height: 17px;
  border-radius: 10px;
  line-height: 17px;
  text-align: center;
  z-index: 5;
  top: 10px;
}
</style>
