<template>
  <div class="send-btn-agree">
    <a-checkbox v-model:checked="isAgree" @change="isAgreeClick">
      {{ $t('login.l_00024') }}
      <a-button type="link" size="small" class="xieyi" @click="targetInfo(1)">《{{ $t('login.l_00025') }}》</a-button>
      {{ $t('login.l_00026') }}
      <a-button type="link" size="small" class="yinsi" @click="targetInfo(2)">《{{ $t('login.l_00027') }}》</a-button>
    </a-checkbox>

    <a-modal v-model:open="xYVisible" :title="xYVisibleFlag === 1 ? $t('login.l_00025') : $t('login.l_00027')"
             :width="920"
             :cancelText="$t('common.com_001')">
      <template #footer>
        <a-button type="primary" @click="cancel">{{ $t('common.com_000') }}</a-button>
      </template>
      <Scroll :height="750">
        <div v-if="xYVisibleFlag === 1" v-html="agreement.agreement"></div>
        <div v-if="xYVisibleFlag === 2" v-html="privacyPolicy.agreement"></div>
      </Scroll>
    </a-modal>
  </div>
</template>
<script>
import { loginMethodInfo } from '@/api/user'

export default {
  data() {
    return {
      isAgree: false,
      xYVisible: false,
      xYVisibleFlag: 1,
      agreement: {},
      privacyPolicy: {},
    }
  },
  created() {
    const rEmail = this.$Cookie.get('rememberEmail')
    if (rEmail) {
      this.isAgree = true
    }
    const rPhone = this.$Cookie.get('rememberPhone')
    if (rPhone) {
      this.isAgree = true
    }
    this.getCacheinfo()
  },
  methods: {
    targetInfo(val) {
      this.xYVisibleFlag = val
      this.xYVisible = true
    },
    getCacheinfo() {
      loginMethodInfo()
          .then(res => {
            this.loginInfo = res.data
            this.agreement = JSON.parse(this.loginInfo.agreement)
            this.privacyPolicy = JSON.parse(this.loginInfo.privacyPolicy)
          })
    },
    cancel() {
      this.xYVisible = false
    },
    isAgreeClick(e) {
      this.isAgree = e.target.checked
      this.$emit('onChange', this.isAgree)
    }
  }
}

</script>
<style scoped lang="scss">
.send-btn-agree {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
}

</style>
