<template>
  <div class="container width_1200_auto">
    <a-image :preview="false" :src="require('@/assets/share/posterbanner.png')" width="100%" :height="227"/>
    <div>
      <div class='distribution-posters'>
        <div style="padding: 10px 0">
          <a-tabs v-model:activeKey="activeGxKey" @change="changeGxActive">
            <a-tab-pane key="1">
              <template #tab>
                <span class="tbs-font">海报</span>
              </template>
            </a-tab-pane>
            <a-tab-pane key="2">
              <template #tab>
                <span class="tbs-font">文案</span>
              </template>
            </a-tab-pane>
          </a-tabs>
        </div>
        <a-row :gutter="24" v-if="activeGxKey === '1'" style="padding-left: -24px;padding-right: -24px;">
          <a-col :span="6" v-for="(item, index) in spreadInfo.shareList" :key="index" class="cu-center flex-column">
            <a-card :bodyStyle="{padding: '10px', textAlign: 'center'}" class="cu-center">
              <div :id="'posterHtml' + index" class="posterBox" :style="{backgroundImage: 'url(' + item + ')' }">
                <div class="qrcodeBox">
                  <div :id="'qrcodeImg' + index"></div>
                </div>
                <div class="infoBox">
                  <div class="mb-3 text-black">{{ infoUser.nickname }}</div>
                  <div class="mb-2 text-black">邀请您加入</div>
                  <div class="mb-1 text-black">邀请码: <span class="text-blue text-bold">{{ invitationCode }}</span></div>
                </div>
              </div>
              <div class="cu-center mt-5 btn-down">
                <a-button type="link" class="myButton" @click="createPoster(index)">下载分享海报</a-button>
              </div>
            </a-card>
          </a-col>
        </a-row>

        <div style="padding: 10px 0" v-else>
          <a-card style="margin-bottom: 20px" :bodyStyle="{padding: '20px '}"
                  v-for="(item,index) in spreadInfo.copyWriterLists" :key="index">
            <div class="content-s">
              <div class="content-font">
                {{ item.info }}
              </div>
              <div class="btn-copy" @click="copy(item)">
                <text class="copy-font">复制</text>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, spreadBanner } from '@/api/user.js'
import { spreadInfo } from '@/api/tg.js'
import { company } from '@/config'

import QRCode from 'qrcodejs2-fix'
import html2canvas from 'html2canvas'

export default {
  data() {
    return {
      imgUrls: [],
      indicatorDots: false,
      circular: false,
      autoplay: false,
      interval: 3000,
      duration: 500,
      swiperIndex: 0,
      spreadList: [],
      poster: '',
      qrcodeSize: 1000,
      PromotionCode: '',
      base64List: [],
      canvasStatus: true, //海报绘图标签
      bgColor: '#e93323',
      invitationCode: '',
      posterImgURL: '',
      indexCurret: 0,
      spreadInfo: {},
      baseList: [
        '@/assets/share/s_1.png',
        '@/assets/share/s_2.png',
        '@/assets/share/s_3.png',
        '@/assets/share/s_4.png',
      ],
      swiperList: ['@/assets/share/posterbanner.png'],
      activeGxKey: '1'
    }
  },
  mounted() {
    if (this.$store.getters.isLogin) {
      this.getUserInfo()
      // this.userSpreadBannerList()
      this.getSpreadInfo()
    }
  },
  methods: {
    copy(item) {
      this.$Copy({ text: item.info })
    },
    changeGxActive(e) {
      this.activeGxKey = e
      if (e === '1') {
        this.getImageBase64(this.spreadInfo.shareList)
      }
    },
    getSpreadInfo() {
      spreadInfo()
          .then((res) => {
            this.spreadInfo = res.data
            if (!this.spreadInfo.bannerList || this.spreadInfo.bannerList.length === 0) {
              this.spreadInfo.bannerList = this.swiperList
            }
            if (!this.spreadInfo.shareList || this.spreadInfo.shareList.length === 0) {
              this.spreadInfo.shareList = this.baseList
            }
            if (!this.spreadInfo.copyWriterLists || this.spreadInfo.copyWriterLists.length === 0) {
              this.spreadInfo.copyWriterLists = this.copyWriterLists
            }
            this.getImageBase64(this.spreadInfo.shareList)
          })
    },
    getUserInfo: function () {
      let that = this
      getUserInfo({
        page: 1,
        limit: 5
      })
          .then(res => {
            this.infoUser = res.data
            that.invitationCode = res.data.invitationCode
          })
    },
    userSpreadBannerList() {
      let that = this
      spreadBanner({
        page: 1,
        limit: 5
      })
          .then(res => {
            that.spreadList = res.data
          })
    },
    getImageBase64(shareList) {
      shareList.forEach((item, index) => {
        setTimeout(() => {
          this.make(index)
        }, 500)
      })
    },
    // 生成二维码；
    make(index) {
      let href = company.href + '/pages/users/invite/index?spread=' + this.invitationCode
      const elementId = 'qrcodeImg' + index
      let qrcodeImgEl = document.getElementById(elementId)
      qrcodeImgEl.innerHTML = ''
      let qrcode = new QRCode(qrcodeImgEl, {
        width: 80,
        height: 80,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      qrcode.makeCode(href)
    },
    createPoster(index) {
      let that = this
      let posterDOM = document.getElementById('posterHtml' + index)
      html2canvas(posterDOM, {
        width: posterDOM.offsetWidth,
        height: posterDOM.offsetHeight,
        //按比例增加分辨率
        scale: window.devicePixelRatio, // 设备像素比
        useCORS: true,//（图片跨域相关）
        allowTaint: true,//允许跨域（图片跨域相关）
        logging: false,
        letterRendering: true,
      })
          .then(function (canvas) {
            that.posterImgURL = canvas.toDataURL('image/png')
            let a = document.createElement('a')
            a.download = '51担保'
            a.href = that.posterImgURL
            a.dispatchEvent(new MouseEvent('click'))
          })
    },
  }
}
</script>

<style lang="scss" scoped>
.content-s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
}

.btn-copy {
  width: 64px;
  height: 26px;
  border-radius: 50px;
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #269FFA;
  text-align: center;

  .copy-font {
    text-align: right;
    font-size: 14px;
    font-weight: 350;
    line-height: 25px;
    letter-spacing: 0px;
    color: #269FFA;
  }
}

.btn-down {
  border-top: 1px solid #E8E8E8;
}

.container {
  margin: 0 auto;
  padding: 3px 0;
  border-radius: 12px;
  min-height: 1200px;
}

.poster-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

/*海报*/
.posterBox {
  position: relative;
  width: 249px;
  height: 407px;
  background-repeat: no-repeat;
  background-size: cover;

  .qrcodeBox {
    position: absolute;
    left: 20px;
    bottom: 5px;
    z-index: 9999;
  }

  .infoBox {
    position: absolute;
    left: 120px;
    bottom: 0;
    z-index: 9999;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
}

.distribution-posters {
  width: 100%;
  height: 100%;
}


</style>
