import axios from 'axios';
import store from '@/store';
import router from '@/router';
import Setting from '@/utils/setting';

let service = axios.create({
    baseURL: Setting.apiBaseURL,
    timeout: 60000, // 过期时间
});

// request interceptor
service.interceptors.request.use(
    (config) => {
        // 发送请求之前做的
        const token = !store.getters.token ? sessionStorage.getItem('token') : store.getters.token;
        if (token) {
            config.headers['Authori-zation'] = token;
        }
        if (/get/i.test(config.method)) {
            config.params = config.params || config.data;
        }
        if (/post/i.test(config.method)) {
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        // if the custom code is not 20000, it is judged as an error.
        if (res.code === 401) {
            router.push({path: '/login'})
        }
        return res;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default service;
