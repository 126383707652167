<template>
  <div>
    <a-modal :open="previewVisible" :footer="null" @cancel="close()" :width="1200" :centered="true" :zIndex="1002"
             :destroyOnClose="true"
             :maskClosable="false"
             :keyboard="false"
             :body-style="{padding: '0px'}"
             :mask-style="{backgroundColor: '#CCCCCC'}">
      <div id="app">
        <div class="sidebar">
          <card @search="searchList"></card>
          <list @change="onChange"
                :dbMerId="dbSelectMerId"
                :keyword="keyword"
                :refreshList="refreshList"
          ></list>
        </div>
        <div class="main">
          <chattitle :selectItem="selectItem" @refresh="refreshMessage"></chattitle>

          <message :selectItem="selectItem"
                   :refreshIndex="refreshIndex"
                   :productInfo="productInfo"
                   :tempMessages="tempMessages"
                   :dbOrderNo="dbOrderNo"
                   @refresh="refreshClear"
                   @close="close"></message>

          <usertext :selectItem="selectItem"
                    @refresh="refreshMessage"
                    @tempMessage="tempMessageMit"
                    :productInfo="productInfo"></usertext>
        </div>
      </div>
    </a-modal>

    <admin-web-socket/>
    <web-socket/>
  </div>
</template>

<script>
import card from '@/components/chat/card.vue'
import list from '@/components/chat/list.vue'
import message from '@/components/chat/message.vue'
import usertext from '@/components/chat/usertext.vue'
import toolbar from '@/components/chat/toolbar.vue'
import chattitle from '@/components/chat/chattitle.vue'
import { msgCusInfoSave, msgCusTempSave } from '@/api/message'

import { getProductDetail, } from '@/api/product.js'
import { getOrderDetail } from '@/api/order'

import WebSocket from '@/components/socket/index.vue'
import AdminWebSocket from '@/components/socket/admin-index.vue'

export default {
  name: 'ChatRoom',
  data() {
    return {
      previewVisible: false,
      dbMerId: 0,
      dbProductId: 0,

      selectItem: {},
      refreshItem: {},
      refreshIndex: -1,
      refreshList: -1,
      keyword: '',
      visitorUserId: '',
      productInfo: {},
      tempMessages: null,
      dbSelectMerId: 0,
      dbOrderNo: ''
    }
  },
  watch: {
    '$store.getters.chatmsgcount': {
      handler(val) {
        this.refreshIndex = val + 1
      }
    }
  },
  created() {
    this.previewVisible = true
    this.dbMerId = this.$route.query.dbMerId
    this.dbProductId = this.$route.query.dbProductId
    this.dbOrderNo = this.$route.query.dbOrderNo
    this.dbSelectMerId = this.dbMerId
    this.visitorSource()
    setTimeout(() => {
      if (this.dbProductId) {
        this.getGoodsDetails()
      }
      if (this.dbOrderNo) {
        this.getOrderInfo(this.dbOrderNo)
      }
      this.refreshMessage()
    }, 500)
  },
  methods: {
    tempMessageMit(msg) {
      if (this.dbSelectMerId === 0 || this.dbSelectMerId === msg.merId) {
        this.tempMessages = msg
      }
    },
    visitorSource() {
      if (!this.$store.getters.uid && this.dbMerId > 0) {
        return
      }
      let data = {
        uid: this.$route.query.uid,
      }
      msgCusTempSave(data)
          .then(res => {
            this.visitorUserId = res.data
            this.saveListMsg()
          })
    },
    saveListMsg() {
      let data = {
        merId: this.dbMerId
      }
      msgCusInfoSave(data)
          .then(res => {
            this.refreshIndex = this.refreshIndex + 1
            this.$store.commit('VISITOR_USER_ID', this.visitorUserId)
          })
    },
    refreshClear(e) {
      if (e === 'clear') {
        this.tempMessages = null
      }
      this.refreshMessage()
    },
    getOrderInfo(orderNo) {
      let that = this
      that.tempMessages = null
      getOrderDetail(orderNo)
          .then(res => {
            that.orderInfo = res.data
            if (res.data) {
              setTimeout(() => {
                that.tempMessages = {
                  type: 1,
                  merId: that.orderInfo.merId,
                  linkId: orderNo,
                  orderNo: orderNo,
                  title: '',
                  icon: that.orderInfo.merchantOrderList[0].orderInfoList[0].image,
                  price: that.orderInfo.payPrice,
                  describe: '',
                  isSend: true,
                  orderTime: that.orderInfo.createTime,
                }
              }, 500)
            }
          })
    },
    getGoodsDetails: function () {
      getProductDetail(this.dbProductId)
          .then(res => {
            this.productInfo = res.data.productInfo
            setTimeout(() => {
              this.tempMessages = {
                type: 1,
                linkId: this.dbProductId,
                orderNo: '',
                title: this.productInfo.name,
                icon: this.productInfo.image,
                price: this.productInfo.price,
                describe: '',
                isSend: true,
                msgTime: '',
              }
            }, 500)
          })
          .catch(err => {
          })
    },
    searchList(e) {
      this.keyword = e
    },
    onChange(item) {
      this.selectItem = item
      this.dbSelectMerId = this.selectItem.merId
      this.tempMessages = null
      this.refreshIndex = this.refreshIndex + 1
    },
    refreshMessage() {
      this.refreshIndex = this.refreshIndex + 1
      this.refreshList = this.refreshList + 1
    },
    close() {
      this.previewVisible = false
      window.close()
    }
  },
  components: {
    toolbar,
    card,
    list,
    message,
    usertext,
    chattitle,
    AdminWebSocket,
    WebSocket
  }
}
</script>

<style lang="scss" scoped>
#app {
  margin: 20px auto;
  width: 100%;
  height: 680px;
  overflow: hidden;
  border-radius: 10px;

  .sidebar, .main, .toolbar {
    height: 100%;
  }

  .toolbar {
    float: right;
    width: 360px;
  }

  .sidebar {
    float: left;
    width: 240px;
  }

  .main {
    position: relative;
    overflow: hidden;
  }

  .right {
    float: right;
    width: 320px;
  }
}
</style>
