<template>
  <div class="page-container-all width_1200_auto">
    <a-image :preview="false" class="all-page-container" :src="require('@/assets/infor/banner.png')"></a-image>

    <div class="width_1200_auto">
      <a-row :gutter="[16,24]">
        <a-col :span="18" style="width: 100%;">
          <a-card :bordered="false" :body-style="{padding: '20px'}"
                  class="mt-3"
                  style="width: 100%;">
            <div class="d-flex justify-between">
              <div class="me-auto d-flex">
                <a-avatar :src="fromData.avatar">
                  <template #icon>
                    <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
                  </template>
                </a-avatar>
                <div class=" d-flex ">
                  <div class="ml-3 ">
                    <div>{{ fromData.nickname }}</div>
                    <div class="text-gray text-sm">
                      {{ comparedateEnable(fromData.createTime) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="padding-xs" style="white-space: pre-wrap;">
              {{ fromData.contentInfo }}
            </div>
            <div class="d-flex">
              <a-row :gutter="[16,24]" style="width: 100%;">
                <a-col :span="4" v-for="(items,indexs) in fromData.images" :key="indexs" class="mr-3">
                  <a-image :src="items" :width="143" :height="143" class="img-fit"/>
                </a-col>
              </a-row>
            </div>

            <div class="d-flex justify-end align-center">
              <div class="grid text-center col-3 text-gray">
                <div class="padding-xs flex justify-start align-center">
                  <div class="ml-2 ">
                    <a-image :preview="false" :src="require('@/assets/info/zan.png')" width="19px" height="19px" class="img-fit"
                             @click="clickZan(fromData)"></a-image>
                  </div>
                  <div class="ml-2">{{ fromData.likeCount }}</div>
                </div>
                <div class="padding-xs flex justify-center align-center">
                  <div class="ml-2">
                    <a-image :preview="false" :src="require('@/assets/info/ping.png')" width="19px" height="19px" class="img-fit"
                             @click="clickPing(fromData)"></a-image>
                  </div>
                  <div class="ml-2">{{ fromData.remarkCount }}</div>
                </div>
                <div class="padding-xs flex justify-end align-center">
                  <div class="ml-2">
                    <a-image :preview="false" :src="require('@/assets/info/share.png')" width="19px" height="19px" class="img-fit"
                             @click="clickShare(fromData)"></a-image>
                  </div>
                  <div class="ml-2">{{ fromData.shareCount }}</div>
                </div>
              </div>
            </div>
          </a-card>
          <div class="mt-3" style="width: 100%;">
            <div style="width: 100%;">
              <a-textarea id="textarea" :rows="4" v-model:value="remark" placeholder='说点什么?'></a-textarea>
            </div>

            <div class="d-flex justify-between">
              <div class="me-auto" style="width: 100%;">
                <a-popover class="emoBox" placement="bottom">
                  <template #content>
                    <div class="emotionList">
                      <a href="javascript:void(0);" @click="getEmo(index)" v-for="(item,index) in faceList" :key="index"
                         class="emotionItem">{{ item }}
                      </a>
                    </div>
                  </template>
                  <a-button type="link" id="emojiBtn" class="emotionSelect" slot="reference">
                    <template #icon>
                      <smile-outlined/>
                    </template>
                  </a-button>
                </a-popover>
              </div>
              <div class="send-btn cursor-pointer" @click="doSend">
                发送
              </div>
            </div>

            <a-card :body-style="{padding: '20px'}" class="mt-2 mb-6">
              <div class="text-gray d-flex justify-between">
                <div class="me-auto">
                  评论: ({{ totalSize }})
                </div>
                <div>
                  <a-tabs v-model:activeKey="curNow" @change="changeActive">
                    <a-tab-pane :key="0"><template #tab>默认</template></a-tab-pane>
                    <a-tab-pane :key="1"><template #tab>最新</template></a-tab-pane>
                  </a-tabs>
                </div>
              </div>

              <div v-if="!cusList || cusList.length === 0">
                <empty></empty>
              </div>
              <div v-else class="cu-card no-card dynamic margin-top-sm" v-for="(item,index) in cusList" :key="index">
                <div class="cu-list menu-avatar comment">
                  <div class="cu-item" style="padding: 10px 20px 20px 80px !important;">
                    <div class="cu-avatar round">
                      <a-avatar :src="item.avatar">
                        <template #icon>
                          <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
                        </template>
                      </a-avatar>
                    </div>
                    <div class="content" style="flex:1;">
                      <div class="text-grey">{{ item.nickname }}</div>
                      <div class="text-df" style="word-break:break-all;">
                        {{ item.content }}
                      </div>
                      <div class="flex justify-between align-center">
                        <div class="text-gray text-df">
                          {{ comparedateEnable(item.createTime) }}
                        </div>
                        <div class="margin-top-xs flex justify-between">
                          <div class="flex align-center justify-center">
                            <a-image :preview="false" :showLoading="true" :src="require('@/assets/info/zan.png')"
                                     width="18px"
                                     height="18px"
                                     @click="clickZanRecord(item)"></a-image>
                            <div class="margin-left-xs">{{ item.likeCount }}</div>
                            <div class="text-black margin-left cursor-pointer" @click="remarkSend(item)">回复</div>
                          </div>
                        </div>
                      </div>
                      <div class="radius margin-top-xs text-sm"
                           v-if="item.replyCount > 0 && moreItemIndex !==  item.id">
                        <div class="flex">
                          <div @click="searMoreRecord(item)">更多 {{ item.replyCount }} 条回复</div>
                        </div>
                      </div>

                      <div v-if="showTextarea && item.id === recordIndex" class="mt-2">
                        <a-textarea :rows="4" v-model:value="remarkIn" placeholder='回复'></a-textarea>
                        <div class="send-btn cursor-pointer" @click="doSendIn">
                          发送
                        </div>
                      </div>

                      <div v-if="onpageshow && moreItemIndex === item.id" v-for="(item2,index2) in contentRecord"
                           :key="index2">
                        <div class="padding-top-sm radius text-sm flex">
                          <a-avatar :src="item2.avatar">
                            <template #icon>
                              <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
                            </template>
                          </a-avatar>
                          <div class="flex flex-column margin-left-xs">
                            <div class="text-gray">{{ item2.nickname }}</div>
                            <div class="flex-sub">{{ item2.content }}</div>
                            <div class="text-xs text-gray">{{ comparedateEnable(item2.createTime) }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="onpageshow && moreItemIndex === item.id"
                           @click="onpageshow = false;moreItemIndex = -1">
                        收起
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-3 mb-3">
                <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                              :show-size-changer="false"
                              :size="'small'"
                              @page-size-change="changePageSize"/>
              </div>
            </a-card>
          </div>
        </a-col>

        <a-col :span="6" class="mt-3">
          <a-card :body-style="{padding: '10px'}">
            <template #title>
              热门话题
            </template>
            <div v-if="hots.length ===0">
              <empty/>
            </div>
            <div v-else class="text-cut mt-2 mb-3" style="width: 200px;" v-for="(item ,index) in hots" :key="index">
              <a href="javascript:void(0);" class="text-black hover-color-cus" @click="toInforDetails(item)">·
                {{ item.contentInfo }}</a>
            </div>
          </a-card>
        </a-col>

      </a-row>
    </div>
  </div>
</template>
<script>

import {
  infoById,
  infoRemark,
  infoRemarkList,
  inforemarkZan,
  inforList,
  numCountPlus,
  remarkZanSecond
} from '@/api/infor'

import texthide from '@/components/texthide/index.vue'
import { PlusOutlined, SmileOutlined } from '@ant-design/icons-vue'
import InfosAdd from '@/components/infos/index.vue'
import { company } from '@/config'
import { formatChatListTime } from '@/utils/chat'

const appData = require('../../utils/emoji.json')//引入存放emoji表情的json文件

export default {
  components: {
    SmileOutlined,
    texthide,
    PlusOutlined,
    InfosAdd,
  },
  data() {
    return {
      fromData: {},
      infoId: 0,
      remark: '',
      faceList: [],
      curNow: 0,
      totalSize: 0,
      cusList: [],
      limit: 10,
      page: 1,
      total: 0,
      onpageshow: false,
      moreItemIndex: -1,
      moreItem: {},
      contentRecord: null,
      onFocus: false,
      item: null,
      showTextarea: false,
      recordIndex: -1,
      remarkIn: '',
      hots: []
    }
  },
  watch: {
    '$route.query.inforId': {
      handler(val) {
        this.infoId = this.$route.query.inforId
        this.queryInfoById(this.infoId)
        this.upCallback()
        this.infoHots()
      }
    }
  },
  mounted() {
    const emojiDate = appData.data.split(',')
    for (let i in emojiDate) {//读取json文件保存数据给数组
      this.faceList.push(emojiDate[i])
    }
  },
  created() {
    this.infoId = this.$route.query.inforId
    this.queryInfoById(this.infoId)
    this.upCallback()
    this.infoHots()
  },
  methods: {
    toInforDetails(item) {
      this.$router.push({
        path: '/Details',
        query: { inforId: item.id }
      })
    },
    infoHots() {
      let data = {
        page: 1,
        limit: 8,
        itemId: 0
      }
      inforList(data)
          .then((res) => {
            this.hots = res.data.list
            this.hots.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
              }
            })
          })
    },
    clickPing(item) {
      console.log(item)
    },
    clickZanRecord(item) {
      let data = {
        replyId: item.id
      }
      inforemarkZan(data)
          .then(res => {
            this.upCallback()
          })
    },
    changeActive(event) {
      this.curNow = event
      this.page = 1
      this.upCallback()
    },
    remarkSend(item) {
      this.showTextarea = !this.showTextarea
      this.recordIndex = item.id
      this.item = item
    },
    upCallback() {
      let data = {
        page: this.page,
        limit: this.limit,
        infoId: this.infoId,
        replyId: this.curNow
      }
      infoRemarkList(data)
          .then((res) => {
            let curPageData = res.data.list
            this.totalSize = res.data.total
            this.total = res.data.total
            this.cusList = curPageData //追加新数据
          })
          .catch(err => {
          })
    },
    searMoreRecord(item) {
      this.moreItem = item
      this.moreItemIndex = item.id
      let data = {
        infoId: this.infoId,
        replyId: this.moreItem.id
      }
      remarkZanSecond(data)
          .then(res => {
            this.contentRecord = res.data
            this.onpageshow = true
          })
    },
    doSendIn() {
      if (!this.remarkIn) {
        return
      }
      let data = {
        infoId: this.infoId,
        replyId: this.item ? this.item.id : 0,
        replyUid: this.item ? this.item.uid : 0,
        content: this.remarkIn
      }
      infoRemark(data)
          .then(res => {
            this.showTextarea = false
            this.remarkIn = ''
            this.upCallback()
            if (this.onpageshow) {
              this.searMoreRecordS()
            }
          })
    },
    doSend() {
      if (!this.remark) {
        return
      }
      let data = {
        infoId: this.infoId,
        replyId: this.item ? this.item.id : 0,
        replyUid: this.item ? this.item.uid : 0,
        content: this.remark
      }
      infoRemark(data)
          .then(res => {
            this.remark = ''
            this.upCallback()
            if (this.onpageshow) {
              this.searMoreRecordS()
            }
          })
    },
    searMoreRecordS() {
      let data = {
        infoId: this.infoId,
        replyId: this.moreItem.id
      }
      remarkZanSecond(data)
          .then(res => {
            this.contentRecord = res.data
          })
    },
    comparedateEnable(time) {
      return formatChatListTime(time)
    },
    clickZan(item) {
      let data = {
        id: item.id,
        itemId: 0
      }
      numCountPlus(data)
          .then(res => {
            this.queryInfoById(this.infoId)
          })
    },
    clickShare(item) {
      let data = {
        id: item.id,
        itemId: 2
      }
      numCountPlus(data)
          .then(res => {
            this.queryInfoById(this.infoId)
            let value = company.href + '/Details?inforId=' + item.id
            this.$Copy({ text: value })
          })
    },
    queryInfoById(infoId) {
      infoById(infoId)
          .then(res => {
            let info = res.data
            this.fromData = info
            console.log(this.fromData)
            if (info && info.images) {
              let imgs = info.images.split(',')
              let images = []
              imgs.forEach(item => {
                images.push(item)
              })
              this.fromData.images = images
            }
          })
    },
    // 分页 修改页码
    changePageNum(val) {
      this.page = val
      this.upCallback()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.page = 1
      this.limit = val
      this.upCallback()
    },
    //获取Emoji
    getEmo(index) {
      var textArea = document.getElementById('textarea')

      //将选中的表情插入到输入文本的光标之后
      function changeSelectedText(obj, str) {
        if (window.getSelection) {
          // 非IE浏览器
          textArea.setRangeText(str)
          // 在未选中文本的情况下，重新设置光标位置
          textArea.selectionStart += str.length
          textArea.focus()
        } else if (document.selection) {
          // IE浏览器
          obj.focus()
          var sel = document.selection.createRange()
          sel.text = str
        }
      }

      changeSelectedText(textArea, this.faceList[index])
      this.remark = textArea.value// 要同步data中的数据
      // console.log(this.faceList[index]);
      return

    },
  }
}
</script>

<style scoped lang="scss">
.send-btn {
  margin-top: 10px;
  width: 100px;
  height: 33px;
  border-radius: 8px;
  opacity: 1;
  background: #269FFA;

  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;

}

.emotionList {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  height: 200px;
  width: 450px;
  overflow: scroll;
  overflow-x: auto;
}

.emotionItem {
  width: 10%;
  font-size: 20px;
  text-align: center;
}

/*包含以下四种的链接*/
.emotionItem {
  text-decoration: none;
}

/*正常的未被访问过的链接*/
.emotionItem:link {
  text-decoration: none;
}

/*已经访问过的链接*/
.emotionItem:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
.emotionItem:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
.emotionItem:active {
  text-decoration: none;
}

.page-container-all {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 3px;
  min-height: 1200px;

  .all-page-container {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 1200px;
  }
}

</style>