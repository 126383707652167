<template>
  <div class="width_1200 container-top">
    <div class="position-relative">
      <a-image :src="require('@/assets/danbao/top.png')" :preview="false" :height="180" :width="'100%'"></a-image>
      <div class="img-btn">
        <a-button class="record-btn mr-3" @click="records">
          我的担保记录
          <template #icon>
            <Icon type="md-list-box" :size="22"/>
          </template>
        </a-button>
      </div>
    </div>
    <div class="mt-3 width_1200">
      <a-card :bodyStyle="{padding: '0'}">
        <a-image :preview="false" :src="require('@/assets/danbao/top.jpg')" :width="1200" :height="160"/>
      </a-card>
      <div>
        <div class="process-step">
          <empty v-if="hisList.length === 0"/>
          <a-card :bodyStyle="{padding: '20px'}" v-else>
            <a-list class="demo-loadmore-list"
                    :loading="listLoading"
                    item-layout="horizontal"
                    :data-source="hisList">
              <template #renderItem="{ item }">
                <a-list-item>
                  <template #actions>
                    <div class="round bg-red cursor-pointer" style="width: 96px !important;"
                         v-if="item.orderStatus === 0">未支付
                    </div>
                    <div>
                      <div class="rounded-xl mb-3 bg-blue cursor-pointer" style="width: 96px !important;"
                           v-if="item.orderStatus === 1 || item.orderStatus === 2"
                           @click="openLink(item.tgUrl)">
                        打开链接
                      </div>
                      <div class="rounded-xl bg-green cursor-pointer" style="width: 96px !important;"
                           v-if="item.orderStatus === 1">
                        担保中
                      </div>
                      <div class="rounded-xl bg-gray cursor-pointer" style="width: 96px !important;"
                           v-if="item.orderStatus === 2">完成
                      </div>
                    </div>
                  </template>
                  <a-skeleton avatar :title="false" :loading="!!listLoading" active>
                    <a-list-item-meta :description="'发起时间:' + item.createTime">
                      <template #title>
                        订单号: {{ item.hisOrder }} <span class="text-grey ml-10">发起金额: {{
                          item.tgAmount
                        }} USDT</span>
                      </template>
                    </a-list-item-meta>
                  </a-skeleton>
                </a-list-item>
              </template>
            </a-list>
          </a-card>
        </div>
        <!-- 分页 -->
        <div class="page-size mt-5" v-if="hisList && hisList.length > 0">
          <a-pagination :page-size="listLimit" :total="listTotal" show-less-items @change="changeListPageNum"
                        :show-size-changer="false"
                        @page-size-change="changeListPageSize"/>
        </div>
      </div>
      <a-card class="mt-3 mb-5" :bodyStyle="{padding: '0'}">
        <a-image :preview="false" :src="require('@/assets/danbao/bottom.jpg')" :width="1200" :height="160"/>
      </a-card>

      <!--      常见问题 -->
      <div class="process-step mb-6">
        <a-card class="margin-top-sm" :bordered="false" :body-style="{padding: '10px'}">
          <template #title>
            <a-image :preview="false" :src="require('@/assets/join/qa.png')" :width="32" :height="32"></a-image>
            常见问题
          </template>
          <a-collapse v-model:activeKey="activeKey" expand-icon-position="end" :bordered="false" ghost accordion>
            <a-collapse-panel :header="item.issue" v-for="(item, index) in issueList" :key="index">
              <div class="html-font" v-html="item.answer"></div>
            </a-collapse-panel>
          </a-collapse>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { pullHis, queryNormalIssue } from '@/api/tg.js'

export default {
  name: 'GuaranteeRecord',
  components: {},
  data() {
    return {
      activeKey: '',
      issueList: [],
      page: 1,
      limit: 12,
      total: 0,
      cusList: [],
      loading: false,
      step: 1,
      current: 0,
      tgData: {},
      color: '#2BA0FF',
      payVisible: false,
      payPassword: '',
      showModel: false,
      tipsOne: '您的余额不足,去充值?',
      tgShareUrl: '',
      listPage: 1,
      listLimit: 12,
      listTotal: 0,
      hisList: [],
      listLoading: false,
    }
  },
  created() {
    this.getPullHisList()
    this.getIssueList()
  },
  methods: {
    openLink(url){
      window.open(url)
    },
    getIssueList() {
      let data = {
        page: 1,
        limit: 100,
        issueType: 0
      }
      queryNormalIssue(data)
          .then(res => {
            this.issueList = res.data.list
          })
    },
    records() {
      this.getPullHisList()
    },
    changeListPageNum(val) { // 修改页码
      this.listPage = val
      this.getPullHisList()
    },
    changeListPageSize(val) { // 修改页数
      this.listPage = 1
      this.listLimit = val
      this.getPullHisList()
    },
    getPullHisList() {
      let that = this
      that.listLoading = true
      let data = {
        page: that.listPage,
        limit: that.listLimit
      }
      pullHis(data)
          .then(function (res) {
            that.hisList = res.data.list
            that.listTotal = res.data.total
            that.listLoading = false
          })
    },
  }
}

</script>
<style scoped lang="scss">
.img-btn {
  position: absolute;
  right: 20px;
  bottom: 20px;

}

.join-btn {
  width: 153px;
  height: 42px;
  border-radius: 6px;
  background: #269FFA;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;

  color: #FFFFFF;
}

.cu-tag {
  width: 63px;
  border-radius: 6px;
}

.share-btn {
  width: 153px;
  height: 42px;
  opacity: 1;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #269FFA;

  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;
  color: #269FFA;
}

.curl-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.17px;
  color: #269FFA;
}


.warning-font {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #7E7E7E;
}

.pay-btn {
  top: 807px;
  width: 299px;
  height: 48px;
  border-radius: 8px;
  background: #269FFA;

  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  color: #FFFFFF;

}

.font-1 {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
  color: #E43737;
}

.content-title {
  width: 98px;
  height: 25px;

  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: #999999;
}

.content-info {
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 0px;
}

.process-step {
  margin-top: 20px;
}

:deep(.ant-steps-item-title) {
  font-size: 14px;
  font-weight: 350;
  line-height: 25px;
  letter-spacing: 0px;
}

.ivu-avatar-large.ivu-avatar-icon {
  font-size: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.ant-steps-item-tail) {
  top: 12px !important;
  margin-inline-start: 60px !important;
}

.danbao-cell-btn1 {
  width: 121px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #E4E4E4;
  color: #3D3D3D;
}

.danbao-cell-btn2 {
  width: 121px;
  height: 40px;
  border-radius: 5px;
  background: #269FFA;
  color: #FFFFFF;
}

.btm-btns {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.cursor-pointer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.list-title {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.list-content {
  margin-top: 6px;
  font-size: 14px;
  color: #999999;
}

.list-g-x {
  padding: 20px 0 20px 10px;
}

.record-btn {
  width: 147px;
  height: 36px;
  border-radius: 50px;
  box-sizing: border-box;
  border: 1px solid #298FFF;

  font-size: 14px;
  font-weight: 350;
  line-height: 26px;
  color: #269FFA;
}

.font-danbao {
  font-size: 16px;
  font-weight: 350;
  line-height: 26px;
  color: #3D3D3D;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0 auto;
  padding: 40px 0;
  border-radius: 12px;
  min-height: 1200px;
}
</style>
