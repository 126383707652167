<template>
  <div class="wrapper">
    <a-card class="cart-users" :bodyStyle="{padding: '30px'}">
      <div class="card-content flex flex-row justify-lg-space-between align-center">
        <div class=" flex flex-row">
          <div>
            <a-avatar :size="92" :src="userInfo.avatar" size="small" @on-error="Avatar">
              <template #icon>
                <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
              </template>
            </a-avatar>
          </div>
          <div class="ml-8 flex justify-center flex-column">
            <div>{{ userInfo.nickname }}</div>
            <div class="user-invitecode">ID:{{ userInfo.invitationCode }}</div>
          </div>
        </div>
        <div class="flex-0-0">
          <a-button class="rounded-xl text-grey" @click="outLogin">退出登录</a-button>
        </div>
      </div>
      <div class="mt-6">
        <Grid :col="5" :border="false">
          <GridItem v-for="(item, index) in orderItem" :key="index">
            <div class="text-center text-h5 font-weight-bold cursor-pointer" @click="toOrder(item)">{{ item.num }}</div>
            <div class="text-center mt-3 cursor-pointer" @click="toOrder(item)">{{ item.name }}</div>
          </GridItem>
        </Grid>
      </div>
    </a-card>
    <!--    海报-->
    <div class="mt-4 mb-4 cursor-pointer">
      <a-image :preview="false" :src="require('@/assets/my/banner.png')"
               @click="$router.push({path: '/Coupon'})"
               :fallback="require('@/assets/index/logo.png')"></a-image>
    </div>
    <!--    订单-->
    <a-card class="list-users" :bodyStyle="{padding: '10px 20px 0 20px'}">
      <div class="card-content flex flex-row justify-lg-space-between align-center">
        <div class="font-weight-bold">
          我的订单
        </div>
        <div class="text-blue text-button cursor-pointer"
             @click="() =>{$router.push({path:'/MyOrder'})}">
          全部订单
        </div>
      </div>
      <div class="mt-3">
        <List :loading="orderLoading">
          <ListItem v-for="(item,index) in orderList" :key="index">
            <ListItemMeta v-for="(items,indexs) in item.orderInfoList" :key="indexs" class="position-relative">
              <template #avatar>
                <div class="cursor-pointer"
                     @click="() => {$router.push({path:'/Product', query:{ id: items.productId}})}">
                  <a-image :preview="false" :width="80" :height="80" :src="items.image"
                           :fallback="require('@/assets/index/logo.png')"></a-image>
                </div>
              </template>
              <template #title>
                <div class="cursor-pointer"
                     @click="() => {$router.push({path:'/Product', query:{ id: items.productId }})}">
                  <span class="font-weight-bold">{{ items.productName }}</span> <span class="">x {{ items.sku }}</span>
                </div>
              </template>
              <template #description>
                <div style="color: #999999;">{{ items.sku }}</div>
                <div class="position-absolute font-weight-bold" style="bottom: 0;color: #F97700;">{{ item.payPrice }}
                  USDT
                </div>
              </template>
            </ListItemMeta>
            <template #action>
              <li>
                <div :style="'color: ' + getColor(item.status)" class="text-right">{{
                    orderStatusFilter(item.status)
                  }}
                </div>
                <div class="mt-6 view-order cursor-pointer"
                     @click="() => {$router.push({path:'/OrderDetails', query:{orderNo: item.orderNo}})}">查看订单
                </div>
              </li>
            </template>
          </ListItem>
        </List>
      </div>
    </a-card>
  </div>
</template>

<script>
import { getOrderList, orderNum } from '@/api/order.js'
import { orderStatusFilter } from '@/filter/commFilter'

export default {
  name: 'Profile',
  data() {
    return {
      userInfo: {},
      orderItem: [{
        name: '待付款',
        status: 0
      }, {
        name: '待发货',
        status: 1
      }, {
        name: '待收货',
        status: 4
      }, {
        name: '待评价',
        status: -10
      }, {
        name: '售后/退款',
        status: -11
      }],
      orderLoading: false,
      orderList: [],
    }
  },
  mounted() {
    let user = localStorage.getItem('USER_INFO')
    if (user) {
      this.userInfo = JSON.parse(user)
    }
  },
  created() {
    this.getOrderData()
    this.getOrderList()
  },
  methods: {
    toOrder(item) {
      this.$router.push({
        path: '/MyOrder',
        query: {
          status: item.status,
        }
      })
    },
    getColor(status) {
      const statusMap = {
        0: '#269FFA',
        1: '#269FFA',
        4: '#FFAB1B',
        5: '#FFAB1B',
        6: '#999999',
        9: '#999999'
      }
      return statusMap[status]
    },
    orderStatusFilter,
    /**
     * 获取订单列表
     */
    getOrderList: function () {
      let that = this
      that.orderLoading = true
      getOrderList({
        status: -1,
        page: 1,
        limit: 8,
      })
          .then(res => {
            let list = res.data.list || []
            that.orderList = that.SplitArray(list, that.orderList)
            this.orderLoading = false
          })
    },
    SplitArray(list, sp) {
      if (typeof list != 'object') return []
      if (sp === undefined) sp = []
      for (var i = 0; i < list.length; i++) {
        sp.push(list[i])
      }
      return sp
    },
    getOrderData() {
      let that = this
      orderNum()
          .then(res => {
            that.orderItem.forEach((item, index) => {
              switch (item.name) {
                case '待付款':
                  item.num = res.data.awaitPayCount
                  break
                case '待发货':
                  item.num = res.data.awaitShippedCount
                  break
                case '待收货':
                  item.num = res.data.receiptCount
                  break
                case '待评价':
                  item.num = res.data.awaitReplyCount
                  break
                default:
                  item.num = res.data.refundCount
              }
            })
          })
    },
    /**
     * 退出登录
     */
    outLogin() {
      this.$store.dispatch('GETLOGOUT')
    },
    Avatar() {
      this.userInfo.avatar = require('@/assets/images/default.png')
    },
  }

}
</script>

<style scoped lang="scss">
.view-order {
  width: 93px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid #C3C3C3;

  font-size: 14px;
  color: #999999;

  display: flex;
  justify-content: center;
  align-items: center;
}

.user-invitecode {
  margin-top: 15px;
  border-radius: 50px;
  background: rgba(179, 179, 179, 0.2);
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  letter-spacing: 0px;
  color: #898989;
}

.wrapper {
  width: 100%;

  .cart-users {
    width: 100%;
    height: 254px;
    border-radius: 12px;
    opacity: 1;
  }

  .list-users {
    width: 100%;
    border-radius: 12px;
    opacity: 1;
  }
}
</style>
