<template>
  <div class="page-container-all width_1200_auto">
    <a-image :preview="false" class="all-page-container" :src="require('@/assets/infor/banner.png')"></a-image>
    <a-card :body-style="{padding: '10px'}" class="width_1200_auto">
      <div class="tabs flex justify-between align-center">
        <div class="tabs ml-3">
          <div :class="seletcedIndex === 0 ? 'tbs-item-selected':'' " class="tbs-item" @click="seletcedTab(0)">热门
          </div>
          <div :class="seletcedIndex === 1 ? 'tbs-item-selected':'' " class="tbs-item" @click="seletcedTab(1)">最新
          </div>
        </div>
        <div>
          <div :class="seletcedIndex === 3 ? 'tbs-item-selected':'' "
               class="margin-right-xs review" @click="myInfoRecord">
            我的发布
          </div>
        </div>
      </div>
    </a-card>

    <a-card style="width: 100%" class="mt-2 cu-center">
      <a-button type="link" class="cursor-pointer" @click="addInfo">
        <PlusOutlined/>
        <span class="ml-2">发布曝光、资讯</span>
      </a-button>
    </a-card>

    <div class="width_1200_auto">
      <a-row :gutter="[16,24]">
        <a-col :span="18">
          <div v-if="!cusList || cusList.length === 0">
            <empty/>
          </div>
          <a-card v-else :bordered="false" :body-style="{padding: '20px'}" v-for="(item,index) in cusList"
                  :key="index"
                  class="mt-3"
                  style="width: 100%;">
            <div class="d-flex justify-between">
              <div class="me-auto d-flex" @click="seletcedIndex === 0 || seletcedIndex === 1 ? clickPing(item) : ''">
                <a-avatar :src="item.avatar">
                  <template #icon>
                    <a-image :src="require('@/assets/images/default.png')" :preview="false"/>
                  </template>
                </a-avatar>
                <div class=" d-flex ">
                  <div class="ml-3 ">
                    <div>{{ item.nickname }}</div>
                    <div class="text-gray text-sm">
                      {{ comparedateEnable(item.createTime) }}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div v-if="seletcedIndex === 3" class="margin-right-xs">
                  <div v-if="item.auditStatus === 0" class="text-gray">
                    审核中
                  </div>
                  <div v-if="item.auditStatus === 1" class="text-red text-cut">
                    未通过
                  </div>
                  <div v-if="item.auditStatus === 2" class="text-green">
                    审核通过
                  </div>
                  <div class="text-right text-blue cursor-pointer" @click="viewInfo(item)">编辑</div>
                </div>
              </div>
            </div>
            <div class="padding-xs" @click="seletcedIndex === 0 || seletcedIndex === 1 ? clickPing(item) : ''">
              <texthide :value="item.contentInfo" :index="index" :item="item"/>
            </div>
            <div class="d-flex">
              <a-row :gutter="[16,24]" style="width: 100%;">
                <a-col :span="4" v-for="(items,indexs) in item.img" :key="indexs" class="mr-3">
                  <a-image :src="items" :width="143" :height="143" class="img-fit"/>
                </a-col>
              </a-row>
            </div>

            <div v-if="seletcedIndex === 0 || seletcedIndex === 1" class="d-flex justify-end align-center">
              <div class="grid text-center col-3 text-gray">
                <div class="padding-xs flex justify-start align-center">
                  <div class="ml-2 ">
                    <a-image :preview="false" :src="require('@/assets/info/zan.png')" width="19px" height="19px"
                             class="img-fit"
                             @click="clickZan(item)"></a-image>
                  </div>
                  <div class="ml-2">{{ item.likeCount }}</div>
                </div>
                <div class="padding-xs flex justify-center align-center">
                  <div class="ml-2">
                    <a-image :preview="false" :src="require('@/assets/info/ping.png')" width="19px" height="19px"
                             class="img-fit"
                             @click="clickPing(item)"></a-image>
                  </div>
                  <div class="ml-2">{{ item.remarkCount }}</div>
                </div>
                <div class="padding-xs flex justify-end align-center">
                  <div class="ml-2">
                    <a-image :preview="false" :src="require('@/assets/info/share.png')" width="19px" height="19px"
                             class="img-fit"
                             @click="clickShare(item)"></a-image>
                  </div>
                  <div class="ml-2">{{ item.shareCount }}</div>
                </div>
              </div>
            </div>

            <div v-else class="padding-lr flex text-right margin-bottom-xs">
              <div v-if="item.auditStatus === 1" class="text-red">
                未通过原因: {{ item.failureInfo }}
              </div>
            </div>
          </a-card>

          <div class="mt-3 mb-3">
            <a-pagination :page-size="limit" :total="total" show-less-items @change="changePageNum"
                          :show-size-changer="false"
                          :size="'small'"
                          @page-size-change="changePageSize"/>
          </div>
        </a-col>
        <a-col :span="6" class="mt-3">
          <a-card :body-style="{padding: '10px'}">
            <template #title>
              热门话题
            </template>
            <div v-if="hots.length ===0">
              <empty/>
            </div>
            <div v-else class="text-cut mt-2 mb-3" style="width: 200px;" v-for="(item ,index) in hots" :key="index">
              <a href="javascript:void(0);" class="text-black hover-color-cus" @click="toInforDetails(item)">·
                {{ item.contentInfo }}</a>
            </div>
          </a-card>
        </a-col>
      </a-row>
    </div>


    <InfosAdd :visible="visible" :formDataPar="formData" @change="evaluateChange"/>
  </div>
</template>
<script>
import { infoById, inforList, numCountPlus, saveInfor, updateInfor } from '@/api/infor.js'

import { formatChatListTime, } from '@/utils/chat.js'

import { company } from '@/config/index.js'

import { PlusOutlined } from '@ant-design/icons-vue'
import texthide from '@/components/texthide/index.vue'
import InfosAdd from '@/components/infos/index.vue'

export default {
  components: {
    texthide,
    PlusOutlined,
    InfosAdd,
  },
  data() {
    return {
      seletcedIndex: 0,
      cusList: [],
      content: [],
      newsPanelHeight: 0,
      myIndex: null,
      page: 1,
      limit: 10,
      total: 0,
      visible: false,
      formData: {},
      hots: [],
    }
  },
  created() {
    this.getInformation()
    this.infoHots()
  },
  methods: {
    infoHots() {
      let data = {
        page: 1,
        limit: 8,
        itemId: 0
      }
      inforList(data)
          .then((res) => {
            this.hots = res.data.list
            this.hots.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
              }
            })
          })
    },
    toInforDetails(item) {
      this.$router.push({
        path: '/Details',
        query: { inforId: item.id }
      })
    },
    clickPing(item) {
      this.$router.push({
        path: '/Details',
        query: { inforId: item.id }
      })
    },
    evaluateChange(formData) {
      this.visible = false
      if (!formData) {
        return
      }
      let dataParam = formData
      let data = {
        title: '',
        contentInfo: dataParam.content,
        images: dataParam.images.toString()
      }
      if (!this.formData) {
        saveInfor(data)
            .then(res => {
              this.getInformation()
            })
      } else {
        let data = {
          infoId: this.formData.id,
          title: '',
          contentInfo: dataParam.content,
          images: dataParam.images.toString()
        }
        updateInfor(data)
            .then((res) => {
              this.getInformation()
            })
      }
    },
    queryInfoById(infoId) {
      infoById(infoId)
          .then(res => {
            let info = res.data
            console.log(info)
            this.formData.id = info.id
            this.formData.content = info.contentInfo
            if (info && info.images) {
              let imgs = info.images.split(',')
              let images = []
              imgs.forEach(item => {
                images.push(item)
              })
              this.formData.images = images
            } else {
              this.formData.images = []
            }
          })
    },
    addInfo() {
      this.formData = null
      this.visible = true
    },
    comparedateEnable(time) {
      return formatChatListTime(time)
    },
    viewInfo(item) {
      this.queryInfoById(item.id)
      setTimeout(() => {
        this.visible = true
      }, 500)
    },
    clickZan(item) {
      let data = {
        id: item.id,
        itemId: 0
      }
      numCountPlus(data)
          .then(res => {
            this.getInformation()
          })
    },
    clickShare(item) {
      let data = {
        id: item.id,
        itemId: 2
      }
      numCountPlus(data)
          .then(res => {
            this.getInformation()
            let value = company.href + '/Details?inforId=' + item.id
            this.$Copy({ text: value })
          })
    },
    myInfoRecord() {
      this.myIndex = 0
      this.seletcedIndex = 3
      this.page = 1
      this.getInformation()
    },
    seletcedTab(index) {
      this.myIndex = null
      this.seletcedIndex = index
      this.page = 1
      this.getInformation()
    },
    // 分页 修改页码
    changePageNum(val) {
      this.page = val
      this.getInformation()
    },
    // 分页 修改页数
    changePageSize(val) {
      this.page = 1
      this.limit = val
      this.getInformation()
    },
    getInformation() {
      let pageNum = this.page // 页码, 默认从1开始
      let pageSize = this.limit // 页长, 默认每页10条
      let data = {
        page: pageNum,
        limit: pageSize,
        itemId: this.seletcedIndex,
        id: this.myIndex
      }
      inforList(data)
          .then((res) => {
            let curPageData = res.data.list
            this.total = res.data.total
            this.cusList = curPageData //追加新数据
            this.cusList.forEach(item => {
              if (item.images) {
                item.img = item.images.split(',')
              }
            })
          })
    },
  }
}
</script>

<style scoped lang="scss">
.review {
  width: 84px;
  height: 26px;
  border-radius: 50px;
  background: rgba(68, 68, 68, 0.6);
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.tabs {
  display: flex;
  background-color: #FFFFFF;
  width: 100%;

  .tbs-item {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 58px;
    height: 24px;
    color: #999999;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20rpx;
  }

  .tbs-item-selected {
    border-radius: 50px;
    background: #269FFA;
    color: #FFFFFF;
  }
}

.page-container-all {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 3px;
  min-height: 1200px;

  .all-page-container {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 1200px;
  }
}

.page-container {
  width: 1200px;
  margin-top: 300px;

  .font-info {
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: 0em;
    color: #7B7B7B;
  }
}
</style>