<template>
  <div class="shadow-box">
    <div class="shadow-item flex flex-a-c flex-j-c mb-4"
         v-for="(item,index) in iconList" @click="handleClickIcon(item)" :key="index">
      <div v-if="item.col === 1">
        <Vue3Lottie :animationData="defaultOptions" :width="'100%'"
                    @animCreated="handleAnimation"/>
        <!--        <a-image :preview="false" v-if="item.icon" class="icon images" :src="require(`@/assets/index/${item.icon}.png`)"/>-->
      </div>
    </div>
    <a-card class="diccc cursor-pointer" :body-style="{padding: '0'}">
      <div class="d-flex flex-column justify-center align-center cursor-pointer" @click="showCusService">
        <div class="customer me-auto">
          <div class="font-top cu-center">
            {{ $t('common.com_003') }}
          </div>
          <div class="cu-center">
            <img
                :src="require('@/assets/index/cou.png')"
                class="cou-img"
                alt=""
                @click="showCusService"
            />
          </div>
        </div>

        <div class="font-bom cu-center mt-6">
          <Icon type="ios-headset-outline" size="18"/>
          {{ $t('common.com_003') }}
        </div>
      </div>
    </a-card>

    <div class="Gotop cu-center mt-5 cursor-pointer" v-if="showTop">
      <div class="font-info-center cu-center" @click="scrollToTop">
        <Icon type="ios-arrow-up" class="mb-1 cu-center" :size="25" />
      </div>
    </div>
  </div>
</template>

<script>
import { Divider } from 'view-ui-plus'
import * as animationData from '@/utils/coupon.json'

export default {
  name: 'fixed-index',
  components: {
    Divider,
  },
  props: {
    showTop: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      iconList: [
        {
          col: 1,
          icon: 'copback',
          label: '优惠券',
          path: '/Coupon'
        }
      ],
      isScrolling: true,
      timeoutId: '',
      previewVisible: false,
      defaultOptions: animationData,
      anim: {}
    }
  },
  mounted() {
    // const vm = this
    // // 添加滚轮事件监听器
    // window.addEventListener('wheel', function() {
    //   if (vm.isScrolling) {
    //     // 如果页面正在滚动，则取消定时器并设置 isScrolling 为 false
    //     window.cancelAnimationFrame(vm.timeoutId);
    //     vm.isScrolling  = false;
    //   }
    // });
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
    },
    showCusService() {
      let routeUrl = this.$router.resolve({
        path: '/ChatIm',
        query: { //要传的参数 可传多个
          dbMerId: 0
        }
      })
      window.open(routeUrl.href, '_blank')
    },
    scrollToTop() {
      const c = document.documentElement.scrollTop || document.body.scrollTop
      if (c > 0) {
        this.timeoutId = window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, c - c / 4)
        this.isScrolling = true
      } else {
        this.isScrolling = false
      }
      this.isScrolling = true
    },
    handleClickIcon(val) {
      if (val.path === 'back') {
        this.scrollToTop()
      } else {
        this.$router.push({
          path: val.path,
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.Gotop {
  .font-info-center {
    width: 42px;
    height: 42px;
    background: #1CA3FF;
    /* 阴影/常规阴影 */
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.16);
    color: #FFFFFF;
    border-radius: 10px;
  }
}

.shadow-box {
  width: 114px;
  height: 384px;
  border-radius: 10px;
  opacity: 1;
}

.shadow-label {
  font-size: 16px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.shadow-item {
  cursor: pointer;
  flex-direction: column;
  //height: 120px;

  .images {
    box-shadow: 0px 1px 10px 0px rgba(154, 154, 154, 0.5);
  }
}

.diccc {
  height: 174px;

  .customer {
    border-radius: 10px;
    width: 100%;

    .font-top {
      padding: 10px 10px;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      text-align: center;
      color: #269FFA;
    }

    .cou-img {
      width: 76.81px;
      height: 76.31px;
    }
  }

  .font-bom {
    height: 32px;
    width: 102%;
    border-radius: 0px 0px 10px 10px;
    opacity: 1;
    background: #269FFA;

    font-size: 14px;
    font-weight: normal;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
  }

}
</style>
