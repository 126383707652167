<template>
  <div>
    <a-modal class="modal-view"
             v-model:open="showModel"
             :maskClosable="false"
             centered>
      <div class="title-info cu-center">{{ tips }}</div>
      <template #footer>
        <div class="cu-center">
          <div class="pay-btn cursor-pointer cu-center" @click="sure">{{ btnTitle }}</div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    showModel: {
      type: Boolean,
      default: false,
      require: false
    },
    tips: {
      type: String,
      default: '您的余额不足,去充值?',
      require: false
    },
    btnTitle: {
      type: String,
      default: '确认',
    }
  },
  data() {
    return {}
  },
  methods: {
    sure() {
      this.$router.push({
        name: 'AccountSafe',
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.forget-link {
  display: flex;
  justify-content: flex-end;
  padding-right: 36px;
}

.title-info {
  font-size: 16px;
  font-weight: 350;
  line-height: 34px;
  letter-spacing: -0.17px;
  color: #3D3D3D;
}

.pay-btn {
  width: 365px;
  height: 50px;
  border-radius: 6px;
  background: #269FFA;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #FFFFFF;
}
</style>
